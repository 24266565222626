import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "plan" }
const _hoisted_2 = {
  key: 0,
  class: "video-type"
}
const _hoisted_3 = {
  key: 1,
  class: "plan-main"
}
const _hoisted_4 = {
  key: 2,
  class: "video-setting"
}
const _hoisted_5 = { key: 3 }

import { ref, computed, onActivated, onDeactivated } from 'vue';
import { useStore } from 'vuex';
import VideoTypeSelectionView from '@/views/Plan/VideoTypeSelection.vue';
import PlanwritingMainView from '@/views/Plan/PlanMainView.vue';
import PlanSetting from '@/views/Plan/PlanSetting.vue';

// 获取 Vuex 状态中的 permissions

export default /*@__PURE__*/_defineComponent({
  __name: 'PlanView',
  setup(__props) {

const store = useStore();

// 使用 computed 来动态计算是否有权限
const hasPermission = computed(() => {
  try {
    // 从 localStorage 中获取 permissions，并解析为数组
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    // 判断是否包含 'create_creative' 权限
    return permissions.includes('create_creative');
  } catch (error) {
    console.error('解析 permissions 出错:', error);
    // 如果解析失败，默认没有权限
    return false;
  }
});

// 生命周期钩子
onActivated(() => {
  console.log('planView 被激活');
});

onDeactivated(() => {
  console.log('planView 被缓存');
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (hasPermission.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(VideoTypeSelectionView)
        ]))
      : _createCommentVNode("", true),
    (hasPermission.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(PlanwritingMainView)
        ]))
      : _createCommentVNode("", true),
    (hasPermission.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(PlanSetting)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[0] || (_cache[0] = [
          _createElementVNode("h1", null, "无权访问该页面", -1)
        ])))
  ]))
}
}

})