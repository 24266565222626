// store/modules/CImage.ts
import { uploadImage, queryImageGeneration, invokeImageGeneration, getAllImageByUser } from '@/api/image'; // 引入接口方法




// 图片对象接口
export interface Image {
    points_consumed: number; // 消耗的点数
    desired_effect: string; // 期望的效果
    created_at: string; // 图片生成时间 (ISO 格式或日期字符串)
    prompt: string; // 图片生成的提示词
    image_url: string; // 引用的图片地址
    generate_uuid: string; // 任务 ID
    user_id: number; // 用户 ID
    username: string; // 用户名
    id: number; // 图片唯一标识
    generated_images: string; // 图片播放地址
}


// Define the state structure for the image module
export interface State {
    imageList: Array<Image>; // 存储生成的图片列表
    currentImageId: string; // 当前正要生成的图片的id
    currentPrompt: string; // 当前图片生成的提示词
    currentImage: string; // 当前用于生成图片的图片
    uploadImage: string;//上传图片的返回url
    loading: boolean; // 是否正在加载中
    isGenerating: boolean; // 是否正在生成中
    currentGeneratedImages: Array<Image>; // 当前已生成的图片列表
    imagesToGenerateCount: number; // 当前需要生成的图片数量
}

// Initial state
const state: State = {
    imageList: [], // 初始图片列表为空
    currentImageId: '1111222', // 初始化图片id为空
    currentPrompt: 'Text layout design', // 初始提示词为空
    currentImage: '', // 初始图片数组为空
    uploadImage : '',
    loading: false, // 默认不在加载状态
    isGenerating: false, // 默认不在生成状态
    currentGeneratedImages: [], // 当前生成的图片列表为空
    imagesToGenerateCount: 0, // 默认需要生成的图片数量为0
};

// Mutations
const mutations = {
    // 设置提示词
    setCurrentPrompt(state: State, currentPrompt: string) {
        state.currentPrompt = currentPrompt;
    },
    // 设置当前图片列表
    setCurrentImage(state: State, currentImage: string) {
        state.currentImage = currentImage;
    },
    // 更新图片列表
    setVodeoList(state: State, images: Array<Image>) {
        state.imageList = images;
    },
    // 添加一个图片到列表
    addImage(state: State, image: Image) {
        state.imageList.unshift(image);
    },
    addImageUrl(state: State, image: Partial<Image>) {
        const newImage: Image = {
            points_consumed: image.points_consumed ?? 0,
            desired_effect: image.desired_effect ?? "",
            created_at: image.created_at ?? new Date().toISOString(),
            prompt: image.prompt ?? "",
            image_url: image.image_url ?? "",  // 如果 image_url 是 undefined，使用空字符串
            generate_uuid: image.generate_uuid ?? "",
            user_id: image.user_id ?? 0,
            username: image.username ?? "",
            id: image.id ?? Date.now(),
            generated_images: image.generated_images ?? "",
        };
        state.imageList.unshift(newImage);
    },
    // 更新加载状态
    setLoading(state: State, isLoading: boolean) {
        state.loading = isLoading;
    },
    // 更新是否正在生成状态
    setIsGenerating(state: State, isGenerating: boolean) {
        state.isGenerating = isGenerating;
    },
    // 设置当前正在生成的图片id
    setCurrentImageId(state: State, currentImageId: string) {
        state.currentImageId = currentImageId;
    },
    // 更新图片列表
    setImageList(state: State, images: Array<Image>) {
        state.imageList = images;
    },
    setImagesToGenerateCount(state: State, count: number) {
        state.imagesToGenerateCount = count;
        console.log('imagesToGenerateCount:', state.imagesToGenerateCount);

    },
    setUploadImage(state: State, imageUrl: string) {
        state.uploadImage = imageUrl;
    },



};

// Actions
const actions = {


    async getAllImageByUser({ commit, rootGetters, state }: any) {
        const userId = rootGetters['auth/userID'];

        // 检查 userId 是否存在
        if (!userId) {
            console.error('User ID is missing.');
            commit('setImageList', []); // 设置空的图片列表
            return; // 直接结束函数
        }

        try {
            // 调用 API
            commit('setLoading', true); // 设置加载状态
            const response = await getAllImageByUser(userId);
            console.log('获取用户的所有图片:', response);

            if (response?.success) {
                // 解析数据并提取 image_url
                const images = response.data?.data.map((image: any) => {
                    let parsedGeneratedImages: Image = { ...image, image_url: '' };
                    try {
                        // 解析 generated_images 字符串
                        parsedGeneratedImages = JSON.parse(image.generated_images);
                    } catch (e) {
                        console.error(`Error parsing generated_images for image id ${image.id}:`, e);
                    }

                    // 返回包含 image_url 的新对象
                    return {
                        ...image,
                        image_url: parsedGeneratedImages?.image_url || '', // 提取 image_url
                    };
                }) || [];

                console.log('Parsed images:', images);

                const image_url = images[0].image_url;
                console.log('image_url:', image_url);

                // 初始化图片列表
                commit('setImageList', images);

                // 打印出图片列表，确保其中包含 id 和 image_url
                console.log('imageList:', images);


            } else {
                console.error('Invalid response format or unsuccessful API response:', response);
                commit('setImageList', []); // 设置空的图片列表
            }
        } catch (error: any) {
            console.error('Error fetching images:', error.message || error);
            commit('setImageList', []); // 设置空的图片列表
        } finally {
            commit('setLoading', false); // 取消加载状态
        }
    },

    // 生成图片并轮询查询状态
    async generateImage(
        { commit, dispatch, rootGetters }: any,
        { imagePrompt, image_id, first_frame_image }: {
            imagePrompt: string;
            image_id: string;
            first_frame_image: string;
        }
    ) {
        const user_id = rootGetters['auth/userID']; // 注意大小写要与 getter 名匹配
        const user_name = rootGetters['auth/username']; // 同上
        try {
            // 1. 触发图片生成
            const videoData = {
                user_id: user_id, // 假设你从 `rootGetters` 获取当前用户 ID
                username: user_name,
                reference_url: state.uploadImage, // 假设你从 `state` 获取上传的图片 URL
                prompt: imagePrompt,
                desired_effect: '重新上色', // 根据需要调整
            };

            console.log('videoData:', videoData);
            
            const response2 = await invokeImageGeneration(videoData);
            console.log('图片生成请求成功:', response2);

            const taskId = response2.generateUuid; // 获取生成任务的 UUID
            const userId = user_id;
            
            commit('addImageUrl', {
                image_url: 'https://i.imgur.com/llF5iyg.gif'
            });

            console.log(state.imageList);
            

            // 2. 轮询查询生成状态
            const maxRetries = 600; // 设置最大轮询次数
            let retries = 0;
            let imageUrl = '';

            while (retries < maxRetries) {
                retries++;

                // 查询生成状态
                const statusResponse = await queryImageGeneration(taskId, userId);
                console.log('查询图片生成状态:', statusResponse);

                // 检查状态是否包含 image_url
                if (statusResponse && statusResponse.image_url) {
                    imageUrl = statusResponse.image_url;
                    if (imageUrl) {
                        console.log("Image generated successfully:", imageUrl);
                        break; // 生成完成，跳出循环
                    }
                }

                // 如果没有生成图片，继续等待
                console.log(`Attempt ${retries} - Image still being generated...`);

                // 如果图片未生成，等待 5 秒后重试
                await new Promise(resolve => setTimeout(resolve, 5000)); // 等待 5 秒
            }

            if (!imageUrl) {
                console.error("Image generation failed or timed out.");
                return;
            }
            

            console.log("图片生成成功，更新图片列表");
            // 3. 更新状态或处理生成的图片 URL
            await dispatch('getAllImageByUser');
           
            
            return imageUrl;

        } catch (error) {
            alert('图片生成失败，请重试，多次失败请联系管理员');
            console.error("Error in generating image:", error);
            throw error;
        }
    },

    // 上传图片
    async uploadImageByAPI({ commit, rootGetters, state }: any, file: File) {
        try {

            const formData = new FormData();
            formData.append('image', file);

            const response = await uploadImage(formData); // 上传图片请求
            console.log('上传图片成功:', response);

            if (response.success) {
                const image_url = response.image_url;
                commit('setUploadImage', image_url); // 更新图片列表
                console.log(state.uploadImage);
                alert('上传图片成功');
                return true;
            } else {
                console.error('上传图片失败:', response);
                alert('上传图片失败，请重试，多次失败请联系管理员');
                return null;
            }
        } catch (error) {
            console.error('上传图片失败:', error);
            return null;
        }
    },
};




// Getters
const getters = {
    // 获取当前图片列表
    imageList: (state: State) => state.imageList,
    // 获取当前提示词
    currentPrompt: (state: State) => state.currentPrompt,
    // 获取当前图片列表
    currentImage: (state: State) => state.currentImage,
    // 获取加载状态
    isLoading: (state: State) => state.loading,
    // 获取是否正在生成中
    isGenerating: (state: State) => state.isGenerating,
    // 获取当前正在生成的图片id
    currentImageId: (state: State) => state.currentImageId,
    // 获取选择生成的图片数量
    imagesToGenerateCount: (state: State) => state.imagesToGenerateCount,
    // 获取上传图片的返回url
    uploadImage: (state: State) => state.uploadImage,
};

// Export the module
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
