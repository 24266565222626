// src/store/modules/ViewStoryboard.ts
import { submitCopywriting, imgGeneration, getImg, narrationGeneration, getSession, chatWithBot, upsertStoryboard, getStoryboard, deleteStoryboard } from '@/api/storyboard'; // 引入封装的 API 方法
import store from '@/store'; // 引入 Vuex 实例
import { get } from 'vant/lib/utils';


// 定义表格数据类型
export interface TableRow {
    id: string;
    index: number;
    image: string;
    image_prompt: string;
    content: string;
    voiceover: string;
    shot_size: string;
    camera_motion: string;
    character: string;
    scene: string;
    camera_angle: string;
    duration: string;
    style: string;
    video_prompt: string;
    isGenerating: boolean;
    isVideoGenerating: boolean;
    video_url: string;
}

// 定义默认的 TableRow 值
const DEFAULT_TABLE_ROW: TableRow = {
    id: '',
    index: 0,
    image: '',
    image_prompt: '',
    content: '正在加载',
    voiceover: '',
    shot_size: '',
    camera_motion: '',
    character: '',
    scene: '',
    camera_angle: '',
    duration: '5',
    style: '',
    video_prompt: '',
    isGenerating: false,
    isVideoGenerating: false,
    video_url: '',
};



// 定义 state
const state = {
    tableData: <TableRow[]>(
        [
            {
                id: '200001',
                index: 1,
                image: "https://liblibai-online.liblib.cloud/sd-gen-save-img/227554694-945dd8ff7a4ca82b67038cbbfd00a790cf551be85138747cde1d2501bff83d69.png?Token=9ca9ce05cf11492ba63eee3946ae1943&image_process=format,webp&x-oss-process=image/resize,w_280,m_lfit/format,webp",
                image_prompt: '图像中包含年轻夫妇在驾驶小米SU7的场景，镜头从车辆侧面捕捉，展示车辆穿梭在城市中的流畅性，背景音乐节奏明快，突出城市的活力',
                content: '年轻夫妇驾驶小米SU7在城市中穿梭',
                voiceover: '轻触方向盘，城市脉搏尽在掌握',
                shot_size: '全景',
                camera_motion: '跟拍镜头',
                character: '年轻夫妇',
                scene: '城市街道',
                camera_angle: '侧面（45°）',
                duration: '5',
                style: '现代都市风',
                video_prompt: '视频中包含年轻夫妇在驾驶小米SU7的场景，镜头从车辆侧面捕捉，展示车辆穿梭在城市中的流畅性，背景音乐节奏明快，突出城市的活力。',
                isGenerating: false,
                isVideoGenerating: false,
                // video_url: 'https://api.aizdc.com/static/video/video_202449939357841.mp4',
                video_url: '',
            },
            {
                id: '200002',
                index: 2,
                image: "https://liblibai-online.liblib.cloud/sd-gen-save-img/228277962-5c98ae6def414f7f13810c4941eb899c69c7fa7ecb0ea58090c6af4ef912786d.png?Token=9ca9ce05cf11492ba63eee3946ae1943",
                image_prompt: '图像中展示商务精英在车内通过高科技设备进行视频会议的场景，镜头聚焦于人物和屏幕交互的细节，背景音乐沉稳大气，强调智能座舱的便利',
                content: '商务精英在车内进行视频会议',
                voiceover: '移动办公室随心所欲',
                shot_size: '中景',
                camera_motion: '前推镜头',
                character: '商务精英',
                scene: '车内',
                camera_angle: '正侧面',
                duration: '5',
                style: '商务风',
                video_prompt: '视频中展示商务精英在车内通过高科技设备进行视频会议的场景，镜头聚焦于人物和屏幕交互的细节，背景音乐沉稳大气，强调智能座舱的便利',
                isGenerating: false,
                isVideoGenerating: false,
                video_url: '',
                // video_url: 'https://api.aizdc.com/static/video/video_202454117159040.mp4',
            },
        ] // 初始化为空数组
    ),
    ratio: '16:9', // 图片比例
    bot_id: '',
    conversation_id: '',
    isLoad: false,
    originality_bot_id: '',
    isGeneration: false,
    storybpard_id: '',
    storyboard_name: '分镜列表',
};

// 在 Vuex 模块初始化时检查 tableData，如果为空则添加默认值
if (state.tableData.length === 0) {
    state.tableData.push({ ...DEFAULT_TABLE_ROW });
}

// 定义 mutations
const mutations = {

    setCurrentScriptType(state: any) {
        const type = store.getters["ViewPlan/getScriptType"]
        console.log("type === ", type);
        // 根据 scriptType 设置不同的 bot_id
        if (type === 'advertising') {
            state.bot_id = '7437437597217390648'; // 汽车广告分镜大师的bot_id
        } else if (type === 'trailer') {
            state.bot_id = '7437437597217390648'; // 宣传片的 bot_id
        } else if (type === 'broadcasting') {
            state.bot_id = '7437437597217390648'; // 口播片的 bot_id
        } else if (type === 'cartoon') {
            state.bot_id = '7437437597217390648'; // 口播片的 bot_id
        } else {
            state.bot_id = ''; // 清空 bot_id 或设置为默认值
        }
    },

    setConversationId(state: any, conversation_id: string) {
        state.conversation_id = conversation_id;
    },

    // 根据 id 设置 isGenerating 的值
    setIsGenerating(state: { tableData: TableRow[] }, { id, isGenerating }: { id: string; isGenerating: boolean }) {
        const rowIndex = state.tableData.findIndex((row) => row.id === id);
        if (rowIndex !== -1) {
            // 找到对应的行并更新 isGenerating 值
            state.tableData[rowIndex].isGenerating = isGenerating;
        } else {
            console.error(`Row with id ${id} not found.`);
        }
    },

    // 根据 id 设置 isVideoGenerating 的值
    setIsVideoGenerating(state: { tableData: TableRow[] }, { id, isVideoGenerating }: { id: string; isVideoGenerating: boolean }) {
        const rowIndex = state.tableData.findIndex((row) => row.id === id);
        if (rowIndex !== -1) {
            // 找到对应的行并更新 isGenerating 值
            state.tableData[rowIndex].isVideoGenerating = isVideoGenerating;
        } else {
            console.error(`Row with id ${id} not found.`);
        }
    },

    // 根据 id 设置 cameraMotion 的值
    setCameraMotion(state: { tableData: TableRow[] }, { id, cameraMotion }: { id: string; cameraMotion: string }) {
        const rowIndex = state.tableData.findIndex((row) => row.id === id);
        if (rowIndex !== -1) {
            // 找到对应的行并更新 isGenerating 值
            state.tableData[rowIndex].camera_motion = cameraMotion;
        } else {
            console.error(`Row with id ${id} not found.`);
        }
    },

    setTableData(state: { tableData: TableRow[] }, data: TableRow[]) {
        // 合并传入的数据和默认值
        state.tableData = data.map(row => ({
            ...DEFAULT_TABLE_ROW, // 默认值
            ...row,               // 覆盖默认值
        }));
    },


    // 添加新行
    addRow(state: { tableData: TableRow[] }, payload: { newRow: TableRow; insertIndex: number }) {
        const { newRow, insertIndex } = payload;
        state.tableData.splice(insertIndex, 0, newRow);
        mutations.updateIndexes(state); // 调整索引
    },

    // 更新行
    updateRow(
        state: { tableData: TableRow[] },
        { id, updatedRow }: { id: string; updatedRow: Partial<TableRow> }
    ) {
        const rowIndex = state.tableData.findIndex((row) => row.id === id);
        if (rowIndex !== -1) {
            // 直接修改行数据
            Object.assign(state.tableData[rowIndex], updatedRow);

            // 浅克隆整个数组以触发响应式更新
            state.tableData = [...state.tableData];
        } else {
            console.error(`Error: Row with id "${id}" not found.`);
            throw new Error(`Row with id "${id}" not found.`);
        }
        console.log(state.tableData);
    },


    // 删除行
    deleteRow(state: { tableData: TableRow[] }, id: string) {
        const rowIndex = state.tableData.findIndex((row) => row.id === id);
        if (rowIndex !== -1) {
            state.tableData.splice(rowIndex, 1);
            mutations.updateIndexes(state); // 调整索引
        }
    },

    // 更新所有行的索引
    updateIndexes(state: { tableData: TableRow[] }) {
        state.tableData.forEach((row, index) => {
            row.index = index + 1;
        });
    },
    // 设置图片比例
    setRatio(state: { ratio: string }, ratio: string) {
        state.ratio = ratio;
    },

    setIsLoad(state: { isLoad: boolean }, isLoad: boolean) {
        state.isLoad = isLoad;
    },

    setOriginalityBotId(state: { originality_bot_id: string }, originality_bot_id: string) {
        state.originality_bot_id = originality_bot_id;
    },

    setIsGeneration(state: { isGeneration: boolean }, isGeneration: boolean) {
        state.isGeneration = isGeneration;
    },

    setStoryboardName(state: { storyboard_name: string }, storyboard_name: string) {
        state.storyboard_name = storyboard_name;
    },

};

// 定义 actions
const actions = {

    // 随着不同的片子类型，切换不同的bot
    async updateScriptType({ commit, state, rootGetters }: { commit: any; state: any; rootGetters: any }) {

        const userID = rootGetters['auth/userID'];
        // 确保 bot_id 存在
        if (state.bot_id) {
            try {
                const response = await getSession(state.bot_id, userID);
                if (response.data.success) {
                    commit('setConversationId', response.data.data); // 设置 conversation_id
                    console.log('Conversation ID:', response.data.data);
                } else {
                    console.error('Failed to retrieve conversation:', response.data.data);
                    alert("网络波动，请稍后重试")
                }
            } catch (error) {
                console.log('Error retrieving conversation:', error);
                alert("出现错误，请稍后重试")
            }
        } else {
            console.log('bot_id is not set');
            alert("创意页面未选择bot")
        }
    },

    addRowAction({ commit }: { commit: Function }, { newRow, insertIndex }: { newRow: TableRow; insertIndex: number }) {
        commit('addRow', { newRow, insertIndex });
    },
    updateRowAction({ commit }: { commit: Function }, payload: { id: string; updatedRow: Partial<TableRow> }) {
        commit('updateRow', payload);
    },
    deleteRowAction({ commit }: { commit: Function }, id: string) {
        commit('deleteRow', id);
    },

    // 合成旁白音频
    async generateVoiceoverAction({ state, rootGetters }: { state: any, rootGetters: any }) {

        console.log("合成旁白音频中")

        const voiceTone = rootGetters['VideoSettings/getVoiceTone']; // 调用其他模块的 getter

        console.log(voiceTone)

        console.log(`当前语音音调: ${voiceTone}`);

        // 根据不同的 voiceTone 选择对应的 reference_id
        let reference_id: string;
        switch (voiceTone) {
            case '男-宣传片':
                reference_id = '2e35ecf6cc4e49398196007aeb48c46b'; // 沉稳大气
                break;
            case '女-温柔知性':
                reference_id = '8d232da29c924bbabba75ec23343be0d'; // 女-温柔知性
                break;
            case '男-叙事':
                reference_id = '4f8beeb6daf149af95d2fb3e3ca631b2'; // 男-叙事
                break;
            case '男-新闻广播':
                reference_id = '0a5adebb3d6d4aa7ac0f59c71b872208'; // 男-新闻广播
                break;
            case '男-科技':
                reference_id = '0d741e5aa643425cbece49f9824d18f0'; // 男-科技
                break;
            case '男-讲故事2':
                reference_id = '34682299301c413daa8db617fabae70a'; // 男-讲故事2
                break;
            case '男-讲故事':
                reference_id = 'f1e5000739364721812349f6ab41580f'; // 男-讲故事
                break;
            case '男-房地产':
                reference_id = '5d76f81bb7b54aec9b337da3cf0aa67e'; // 男-房地产
                break;
            case '男-MG动画':
                reference_id = 'f97bd279b0954e0990184b43b28a498a'; // 男-MG动画
                break;
            case '女-讲故事':
                reference_id = '3268d86514f14f8582ad490e9225f833'; // 女-讲故事
                break;
            default:
                reference_id = '2e35ecf6cc4e49398196007aeb48c46b'; // 默认值
                break;
        }


        // 提取所有 voiceover，并拼接成一个完整的字符串
        const combinedVoiceover = state.tableData
            .map((row: TableRow) => row.voiceover)
            .filter((voiceover: string) => voiceover.trim() !== '') // 过滤掉空的旁白
            .join(' '); // 拼接旁白，用空格分隔

        console.log("旁白详情:", combinedVoiceover);


        // 调用音频生成 API
        try {
            // 调用音频生成 API
            const response = await narrationGeneration(combinedVoiceover, reference_id);

            // 从响应中提取生成的音频文件 URL 和时长
            const fileUrl = response.data.file_url;
            const audioDuration = response.data.duration; // 获取音频时长
            console.log("获得的旁白音频:", fileUrl);
            console.log("旁白音频时长:", audioDuration);

            // 将 fileUrl 和时长存储在 Vuex 或其他变量中
            store.commit('ViewEdit/setGeneratedAudioUrl', fileUrl); // 假设有对应的 mutation 来存储 URL
            store.commit('ViewEdit/setGeneratedAudioDuration', audioDuration); // 假设有对应的 mutation 来存储时长

            console.log("合成旁白音频完成")

        } catch (error) {
            console.error("Failed to generate voiceover:", error);
        }
    },


    // 为指定的行生成图片，并更新该行的图片 URL
    async generateImageForRowAction(
        { commit, state }: { commit: Function; state: any },
        id: string
    ) {

        // // 检查是否已有一行的 isGenerating 为 true
        // const generatingRow = state.tableData.find((row: TableRow) => row.isGenerating);
        // if (generatingRow) {
        //     console.warn("已经有一张图片正在生成中，请稍候。");
        //     return; // 直接返回，避免同时生成多张图片
        // }

        // 找到指定行的数据
        const row = state.tableData.find((row: TableRow) => row.id === id);
        const ratio = state.ratio || '16:9'; // 如果 state.ratio 为空，则使用 '16:9' 作为默认比例
        const originality_bot_id = state.originality_bot_id;
        if (row) {
            try {
                // 设置 isGenerating 为 true，表示正在生成中
                commit('setIsGenerating', { id, isGenerating: true });

                // 调用 imgGeneration API，使用 row.image_prompt 作为 prompt 和 row.ratio 作为比例
                const response = await imgGeneration({
                    prompt: row.image_prompt + row.shot_size,
                    ratio: ratio, // 图片比例
                    originality_bot_id: originality_bot_id,
                });

                console.log(response.data);

                const { token, img_mask_id } = response.data;

                // 调用轮询方法
                const previewUrl = await pollGetImg(token, img_mask_id);

                console.log(previewUrl);

                // 更新 Vuex 数据
                commit('updateRow', { id, updatedRow: { image: previewUrl } });

            } catch (error: any) {
                console.error(`生成图片失败, 行 ID: ${id}`, error);
                alert(`生成图片失败, 行 ID: ${id}, 错误: 图片服务器爆满，请稍后再试`); // 弹出提示
            } finally {
                // 无论是否成功，生成完成后将 isGenerating 设置为 false
                commit('setIsGenerating', { id, isGenerating: false });
            }
        } else {
            console.error(`未找到行 ID: ${id}`);
        }
    },


    async checkAndGenerateImage({ commit, state, dispatch }: { commit: Function; state: any; dispatch: Function }) {
        // 遍历表格数据，检查每一行的 image 字段
        for (const row of state.tableData) {
            // 如果图片为默认的 noImage，则生成新图片

            console.log(row);


            if (row.image === require('@/assets/noImage.webp') || row.image === '') {
                try {
                    // 调用 generateImageForRowAction 方法生成图片
                    await dispatch('generateImageForRowAction', row.id);
                } catch (error) {
                    console.error(`无法为行 ID: ${row.id} 生成图片`, error);
                }
            } else {
                console.log("该行的图片好像已经有了图片，不再自动生成")
            }
        }
    },

    async InitStoryboard({ commit, state, rootGetters, dispatch }: { commit: Function; state: any; rootGetters: any, dispatch: Function }, { content }: { content: any }) {

        const originality_bot_id = rootGetters['ViewPlan/getBotId']

        console.log('创意的bot_id为' + originality_bot_id);

        commit('setOriginalityBotId', originality_bot_id)

        // 使用生成的 storyboard_id
        state.storyboard_id = generateStoryboardId();

        // 初始化bot
        console.log('初始化bot中..');

        commit('setCurrentScriptType')
        await dispatch('updateScriptType')

        console.log('初始化bot结束');


        console.log('state.bot_id == ' + state.bot_id);
        console.log('conversation_id == ' + state.conversation_id);

        if (!state.bot_id || !state.conversation_id) {
            alert("机器人初始化错误，请确保创意左侧的表单已选择")
            return;
        }


        // 打印接收到的 combinedContent 数据的每一个参数
        console.log('Received content:');
        for (const [key, value] of Object.entries(content)) {
            console.log(`${key}:`, value);
        }

        // 将 content 转换为字符串形式
        const contentString = JSON.stringify(content, null, 2);  // null 和 2 用于美化输出，2 是缩进的空格数

        console.log('contentString====' + contentString);

        // 假设 content 中有一个属性 "aspectRatio" 需要设置为 ratio
        const ratio = content.aspectRatio; // 从 content 中获取比例
        if (ratio) {
            // 使用 Vuex mutation 设置比例
            commit('setRatio', ratio);
        }

        const user_token = rootGetters['auth/token'];

        // 调用 chatWithBot 方法提交内容
        try {
            console.log("Before API call");
            // 调用 chatWithBot 获取流式数据的 URL
            const response = await chatWithBot({
                user_token: user_token,
                conversation_id: state.conversation_id,
                bot_id: state.bot_id,
                userInput: contentString,
                fileUrl: " "  // 可选的文件 URL
            });

            if (response.data.success) {
                const stream_url = response.data.stream_url;
                console.log("Stream URL:", stream_url);

                // 开始流式处理
                const eventSource = new EventSource(stream_url);

                // 用于拼接流内容
                let content_res = '';

                eventSource.onmessage = async (event) => {
                    const data = JSON.parse(event.data);

                    commit('setIsGeneration', true)

                    if (data.status === 'end') {
                        // 如果流结束，关闭连接
                        console.log("content_res === " + content_res);
                        eventSource.close();
                        console.log('Stream ended');
                        commit('setIsLoad', false); // 更新标志，标记为在加载
                        commit('setIsGeneration', false)

                        // 调用 checkAndGenerateImage 方法，检查并生成图片
                        try {
                            await dispatch('checkAndGenerateImage');
                        } catch (error) {
                            console.error("Error during image generation:", error);
                        }

                    } else if (data.content) {
                        // 累积内容
                        content_res += data.content;
                        console.log(data.content);

                        if (content_res.trim().endsWith("}") || content_res.trim().endsWith("},")) {

                            try {
                                // 去掉第一个字符为 '[' 的情况
                                let processedContent = content_res.trim();
                                if (processedContent.startsWith("[")) {
                                    console.log("以 [ 开头，不符合规范, 去掉");
                                    processedContent = processedContent.slice(1); // 删除第一个字符
                                    console.log(content_res)
                                }

                                if (processedContent.startsWith("```json")) {
                                    console.log("以```json开头，不符合规范, 去掉");
                                    processedContent = processedContent.slice(7); // 删除前7个字符字符 ```json
                                    console.log(content_res)
                                }

                                // 如果以 "}," 结尾，去掉最后的逗号
                                if (processedContent.endsWith("},")) {
                                    console.log("以},结尾  不符合规范,去掉");
                                    processedContent = processedContent.slice(0, -2) + "}"; // 去掉逗号并保留 }
                                    console.log(content_res)
                                }

                                if (processedContent.endsWith("]") || processedContent.endsWith("}]")) {
                                    console.log(content_res)
                                    alert("这段分镜已经生成完毕了！");
                                }

                                const jsonObject = JSON.parse(processedContent); // 尝试解析 JSON 对象
                                // 添加到表格数据
                                if (!state.isLoad) {
                                    // 首次接收数据，清空并设置新数据
                                    commit('setTableData', [
                                        {
                                            ...DEFAULT_TABLE_ROW, // 默认值
                                            ...jsonObject,        // 覆盖默认值
                                        },
                                    ]);
                                    commit('setIsLoad', true); // 更新标志，标记为在加载
                                    console.log(state.isLoad);

                                    // // 提取 JSON 对象的 ID
                                    // const id = jsonObject.id;
                                    // if (id) {
                                    //     dispatch('generateImageForRowAction', id);
                                    //     console.log(`Dispatched action with ID: ${id}`);
                                    // }

                                } else {
                                    // 非首次接收，追加数据
                                    state.tableData.push({
                                        ...DEFAULT_TABLE_ROW, // 默认值
                                        ...jsonObject,        // 覆盖默认值
                                    });
                                }

                                content_res = ""; // 清空缓冲区
                            } catch (e) {
                                // 若解析失败，继续接收剩余字符
                            }
                        }

                    }
                };

                eventSource.onerror = (error) => {
                    console.error('EventSource error:', error);
                    eventSource.close();
                };
            } else {
                console.error("Failed to initiate chat:", response.data);
            }
        } catch (error) {
            console.error("API call failed", error); // 处理错误
        } finally { }

    },


    async generationNext({ commit, state, rootGetters, dispatch }: { commit: Function; state: any; rootGetters: any, dispatch: Function }) {
        if (state.isGeneration) {
            console.log("当前仍在生成中，不能进行下一步。");
            return
        }

        const user_token = rootGetters['auth/token'];

        // 调用 chatWithBot 方法提交内容
        try {
            console.log("Before API call");
            // 调用 chatWithBot 获取流式数据的 URL
            const response = await chatWithBot({
                user_token: user_token,
                conversation_id: state.conversation_id,
                bot_id: state.bot_id,
                userInput: "继续生成",
                fileUrl: " "  // 可选的文件 URL
            });

            if (response.data.success) {
                const stream_url = response.data.stream_url;
                console.log("Stream URL:", stream_url);

                // 开始流式处理
                const eventSource = new EventSource(stream_url);

                // 用于拼接流内容
                let content_res = '';

                eventSource.onmessage = async (event) => {
                    const data = JSON.parse(event.data);
                    commit('setIsGeneration', true)
                    if (data.status === 'end') {
                        // 如果流结束，关闭连接
                        console.log("content_res === " + content_res);
                        eventSource.close();
                        console.log('Stream ended');
                        commit('setIsGeneration', false)
                    } else if (data.content) {
                        // 累积内容
                        content_res += data.content;
                        console.log(data.content);

                        if (content_res.trim().endsWith("}") || content_res.trim().endsWith("},")) {

                            try {
                                // 去掉第一个字符为 '[' 的情况
                                let processedContent = content_res.trim();
                                if (processedContent.startsWith("[")) {
                                    console.log("以 [ 开头，不符合规范, 去掉");
                                    processedContent = processedContent.slice(1); // 删除第一个字符
                                    console.log(content_res)
                                }

                                if (processedContent.startsWith("```json")) {
                                    console.log("以```json开头，不符合规范, 去掉");
                                    processedContent = processedContent.slice(7); // 删除前7个字符字符 ```json
                                    console.log(content_res)
                                }

                                // 如果以 "}," 结尾，去掉最后的逗号
                                if (processedContent.endsWith("},")) {
                                    console.log("以},结尾  不符合规范,去掉");
                                    processedContent = processedContent.slice(0, -2) + "}"; // 去掉逗号并保留 }
                                    console.log(content_res)
                                }

                                if (processedContent.endsWith("]") || processedContent.endsWith("}]")) {
                                    console.log(content_res)
                                    alert("这段分镜已经生成完毕了！");
                                }

                                const jsonObject = JSON.parse(processedContent); // 尝试解析 JSON 对象
                                // 添加到表格数据

                                // 非首次接收，追加数据
                                state.tableData.push({
                                    ...DEFAULT_TABLE_ROW, // 默认值
                                    ...jsonObject,        // 覆盖默认值
                                });

                                console.log(content_res)

                                content_res = ""; // 清空缓冲区
                            } catch (e) {
                                // 若解析失败，继续接收剩余字符
                            }
                        }

                    }
                };

                eventSource.onerror = (error) => {
                    console.error('EventSource error:', error);
                    eventSource.close();
                };
            } else {
                console.error("Failed to initiate chat:", response.data);
            }
        } catch (error) {
            console.error("API call failed", error); // 处理错误
        } finally { }

    },

    async saveStoryboard({ rootGetters, state }: { rootGetters: any, state: any }) {
        // 从 state 中解构需要的字段
        let { originality_bot_id, bot_id, conversation_id, tableData, ratio, storyboard_id, storyboard_name } = state;


        // 获取 user_id 和 user_name，注意要确保它们从 rootGetters 中获取成功
        const user_id = rootGetters['auth/userID']; // 注意大小写要与 getter 名匹配
        const user_name = rootGetters['auth/username']; // 同上

        if (storyboard_id == '') {
            storyboard_id = generateStoryboardId();
        }


        // 检查 user_id 和 user_name 是否有效
        if (!user_id || !user_name) {
            alert("用户信息缺失，请先登录！");
            return;
        }

        // 创建字典对象，准备发送给后端
        const data = {
            user_id,
            user_name,
            originality_bot_id,
            bot_id,
            conversation_id,
            table_data: JSON.stringify(tableData.map(parseDataToTableRow)),  // 转换为 JSON 字符串
            ratio,
            storyboard_id: storyboard_id,
            storyboard_name,
            project_id: '11111', // 临时项目 ID
            project_name: '测试项目', // 临时项目名称
        };

        // 打印日志，确保数据正确
        console.log('准备保存的分镜数据:', data);

        try {
            // 调用 upsertStoryboard 方法，并传入构造好的 data
            const response = await upsertStoryboard(data);

            // 打印返回的完整响应数据
            console.log('服务器响应数据:', response);

            // 判断后端返回的结果是否成功
            if (response.data && response.data.success) {
                console.log('保存成功');
                alert('保存成功');
            } else {
                // 如果保存失败，输出失败信息并提示用户
                console.error('保存失败:', response);
                alert('保存失败，请稍后重试');
            }
        } catch (error) {
            // 捕获请求错误或网络错误
            console.error('保存失败:', error);
            alert('保存失败，网络错误，请稍后重试');
        } finally {
            // 任何情况下都可以执行的操作，例如隐藏加载动画等
            // 可以根据实际需要添加
        }
    },

    async getCurrentStoryboard({ commit, state, rootGetters, dispatch }: { commit: Function; state: any; rootGetters: any, dispatch: Function }) {
        // 从 state 中解构需要的字段
        const storyboard_id = 'storyboard_1732778384818_nls354sha';                         // 故事板 ID
        const user_id = rootGetters['auth/userID'];           // 用户 ID
        const project_id = '11111'; // 项目 ID

        // 调用 getStoryboard 方法，获取指定 ID 的故事板数据
        try {
            const response = await getStoryboard({ storyboard_id, user_id, project_id });

            // 打印返回的完整响应数据
            console.log('服务器响应数据:', response);

            // 判断后端返回的结果是否成功
            if (response.data && response.data.success) {
                // 如果成功，更新 state 中的 storyboard_name 和 tableData
                const table_data = response.data.data.data;

                console.log(table_data);


                const storyboard_name = table_data.storyboard_name;
                console.log(storyboard_name);

                commit('setStoryboardName', storyboard_name);

                state.bot_id = table_data.bot_id;
                state.conversation_id = table_data.conversation_id;
                state.originality_bot_id = table_data.originality_bot_id;
                state.ratio = table_data.ratio;
                state.storyboard_id = table_data.storyboard_id;
                state.storyboard_name = table_data.storyboard_name;
                state.project_id = table_data.project_id;
                state.project_name = table_data.project_name;

                console.log('bot_id:', state.bot_id);
                console.log('conversation_id:', state.conversation_id);
                console.log('originality_bot_id:', state.originality_bot_id);
                console.log('ratio:', state.ratio);
                console.log('storyboard_id:', state.storyboard_id);
                console.log('storyboard_name:', state.storyboard_name);
                console.log('project_id:', state.project_id);
                console.log('project_name:', state.project_name);


                const tableData = JSON.parse(table_data.table_data);
                state.tableData = tableData
                console.log('tableData:', tableData);



            } else {
                // 如果获取失败，输出失败信息并提示用户
                console.error('获取失败:', response);
                alert('获取失败，请稍后重试');
            }
        } catch (error) {
            // 捕获请求错误或网络错误
            console.error('获取失败:', error);
        } finally {
            // 任何情况下都可以执行的操作，例如隐藏加载动画等            
            // 可以根据实际需要添加
        }
    },

};

// 定义 getters
const getters = {

    getStoryboardName: (state: { storyboard_name: string }) => state.storyboard_name,

    getTableData: (state: { tableData: TableRow[] }) => state.tableData,
    getRowById: (state: { tableData: TableRow[] }) => (id: string) =>
        state.tableData.find((row) => row.id === id),
    // 获取指定 id 的 image_prompt
    getImagePromptById: (state: { tableData: TableRow[] }) => (id: string) => {
        const row = state.tableData.find((row) => row.id === id);
        return row ? row.image_prompt : null; // 如果找到对应行，则返回 image_prompt，否则返回 null
    },

    // 获取包含 id 和 image_prompt 的字典
    getImagePromptsDictionary: (state: { tableData: TableRow[] }) => {
        return state.tableData.reduce((acc: Record<string, string>, row) => {
            acc[row.id] = row.image_prompt;
            return acc;
        }, {});
    },
    getImageById: (state: { tableData: TableRow[] }) => (id: string) => {
        const row = state.tableData.find(item => item.id === id);
        return row ? row.image : null;
    },
    getVideoPromptById: (state: { tableData: TableRow[] }) => (id: string) => {
        const row = state.tableData.find(item => item.id === id);
        return row ? row.video_prompt : null;
    },

    getCameraMotionById: (state: { tableData: TableRow[] }) => (id: string) => {
        const row = state.tableData.find(item => item.id === id);
        return row ? row.camera_motion : null;
    },

    getIsGeneratingById: (state: { tableData: TableRow[] }) => (id: string) => {
        const row = state.tableData.find(item => item.id === id);
        return row ? row.isGenerating : null;
    },
    getIsVedioGeneratingById: (state: { tableData: TableRow[] }) => (id: string) => {
        const row = state.tableData.find(item => item.id === id);
        return row ? row.isVideoGenerating : null;
    },

};

// 轮询获取图片生成的预览 URL
const pollGetImg = async (token: string, img_mask_id: string, interval = 10000, maxRetries = 30) => {
    let retries = 0;

    while (retries < maxRetries) {
        try {
            // 调用 getImg 方法
            const response = await getImg({ token, img_mask_id });

            if (response.success && response.preview_url) {
                console.log('图片生成完成，URL:', response.preview_url);
                return response.preview_url; // 返回预览地址
            } else if (response.status === 'queued') {
                console.log(`图片仍在排队中，排队位置: ${response.queue_num}`);
            } else {
                console.error('图片生成失败或状态未知:', response);
            }
        } catch (error: any) {
            console.error('请求失败:', error.message);
            throw error; // 抛出异常跳出循环
        }

        // 等待指定时间间隔后重试
        await new Promise((resolve) => setTimeout(resolve, interval));
        retries++;
    }

    // 如果达到最大重试次数
    throw new Error('图片生成超时，未完成');
};


// 修改后的数据转换函数，用于处理单个 TableRow 对象
function parseDataToTableRow(item: Partial<TableRow>): TableRow {
    return { ...DEFAULT_TABLE_ROW, ...item }; // 合并默认值和解析后的数据
}

// 用于生成唯一的 storyboard_id
function generateStoryboardId() {
    const timestamp = Date.now(); // 获取当前时间戳
    const randomString = Math.random().toString(36).substr(2, 9); // 随机生成一个字符串
    return `storyboard_${timestamp}_${randomString}`; // 生成唯一的 ID
}


// 导出模块
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};