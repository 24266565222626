<template>
  <div class="login-container">
    <div class="login-box">
      <div class="avatar"></div>
      <form @submit.prevent="handleSubmit">
        <div class="input-group">
          <label for="username">用户名</label>
          <input data-v-5afd2294 type="text" v-model="username" id="username" required autocomplete="username">
        </div>
        <div class="input-group">
          <label for="password">密码</label>
          <input data-v-5afd2294 type="password" v-model="password" id="password" required
            autocomplete="current-password">

        </div>
        <button type="submit">登录</button>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axios from 'axios';

const store = useStore();
const router = useRouter();

const username = ref<string>('');
const password = ref<string>('');

const handleSubmit = async () => {
  try {
    // 确保传递的 username 和 password 是字符串类型，并且通过 .value 访问它们的实际值
    if (typeof username.value !== 'string' || typeof password.value !== 'string') {
      throw new Error('用户名或密码必须是字符串');
    }

    // 调用 Vuex 的登录方法，传递 username.value 和 password.value
    const response = await store.dispatch('auth/login', {
      username: username.value,
      password: password.value
    });

    if (response && response.success) {
      // 登录成功，跳转到计划页面
      router.push({ path: '/plan' });
    } else {
      // 登录失败
      console.error('登录失败');
      alert('用户名或密码错误');
    }
  } catch (error) {
    console.error('请求错误:', error);
    alert('登录请求失败，请稍后再试。');
  }
};


</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 30vw;
  height: 60vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.avatar {
  height: 12vh;
  width: 12vh;
  border-radius: 50%;
  background-image: url(../assets/jinmu.jpg);
  background-size: cover;
  background-position: center;
  margin-top: 5vh;
}

.input-group {
  margin-bottom: 15px;
  margin-top: 3vh;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 25px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

button {
  margin-top: 2vh;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
