import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "storyboard" }
const _hoisted_2 = {
  key: 0,
  class: "tool"
}
const _hoisted_3 = {
  key: 1,
  class: "storyboard"
}
const _hoisted_4 = { key: 2 }

import { ref,computed, onActivated, onDeactivated } from 'vue';
import StoryboardTool from './StoryboardTool.vue';
import StoryboardMain from './StoryboardMain.vue';


// 使用 computed 来动态计算是否有权限

export default /*@__PURE__*/_defineComponent({
  __name: 'StoryboardView',
  setup(__props) {

const hasPermission = computed(() => {
  try {
    // 从 localStorage 中获取 permissions，并解析为数组
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    // 判断是否包含 'create_creative' 权限
    return permissions.includes('use_storyboard');
  } catch (error) {
    console.error('解析 permissions 出错:', error);
    // 如果解析失败，默认没有权限
    return false;
  }
});


// 生命周期钩子
onActivated(() => {
  console.log('StoryboardView 被激活');
});

onDeactivated(() => {
  console.log('StoryboardView 被缓存');
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (hasPermission.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(StoryboardTool)
        ]))
      : _createCommentVNode("", true),
    (hasPermission.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(StoryboardMain)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
          _createElementVNode("h1", null, "无权访问该页面", -1)
        ])))
  ]))
}
}

})