<template>
    <div class="separate"></div>

    <!-- 判断是否有权限 -->
    <div v-if="hasPermission" class="image-page">
        <div class="container">
            <!-- 文本输入区域 -->
            <div class="text-area">
                <h2>图片参考图与提示词</h2>
                <div class="upload-container">
                    <!-- 上传图片按钮 -->
                    <div class="upload-img" @click="triggerFileInput">
                        <div><i class="bi bi-box-arrow-up"></i></div>
                        <input type="file" accept="image/*" ref="fileInput" @change="handleImageUpload"
                            style="display: none" />
                    </div>

                    <img v-if="currentImage" :src="currentImage" alt="效果图" class="index-img" crossorigin="anonymous" />

                    <!-- 当图片为空时 -->
                    <div v-else class="placeholder">
                        等待上传
                    </div>



                    <div class="quantity-label">上传的图片需要压缩并保存在云端，请耐心等待上传完成的提示</div>

                    <!-- <button @click="recolor">重新上色</button> -->

                    <!-- 图片数量选择部分 -->
                    <!-- <div class="quantity-section">
                        <div class="quantity-label">生成图片数量:</div>
                        <div class="quantity-buttons">
                            <button v-for="num in [1, 2, 3, 4, 8, 16]" :key="num" @click="setImageCount(num)"
                                :class="{ 'selected': num === imagesToGenerateCount }">
                                {{ num }}
                            </button>
                        </div>
                    </div> -->

                </div>
                <textarea v-model="currentPrompt" placeholder="请输入图片提示词...(目前仅支持英文)"></textarea>
                <button @click="handleGenerateVideo">生成</button>

                <!-- 自定义弹窗 -->
                <div v-show="showModal" class="modal-overlay">
                    <div class="modal">
                        <h4>生成图片确认</h4>
                        <p>每张图片的费用为¥0.25，</p>
                        <p>您确定要生成图片吗？</p>
                        <div class="button-group">
                            <button class="confirm-button" @click="confirmGenerateVideo">确认</button>
                            <button class="confirm-button" @click="cancelGenerateVideo">取消</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 生成的图片区域 -->
            <div class="image-output">
                <h2>图片成品</h2>
                <div class="image-list">
                    <div v-for="image in generatedImages" :key="image.id" class="image-container">
                        <!-- 使用 <img> 标签来显示图片 -->
                        <img :src="image.image_url" alt="Generated Image" crossorigin="anonymous">
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- 如果没有权限，显示无权限提示 -->
    <div v-else>
        <h1>无权访问该页面</h1>
    </div>

</template>


<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// 使用 Vuex store
const store = useStore();

// 使用 computed 来动态计算是否有权限
const hasPermission = computed(() => {
    try {
        // 从 localStorage 中获取 permissions，并解析为数组
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        // 判断是否包含 'create_creative' 权限
        return permissions.includes('generate_image');
    } catch (error) {
        console.error('解析 permissions 出错:', error);
        // 如果解析失败，默认没有权限
        return false;
    }
});

// 默认占位图片路径
const noImage = require('@/assets/noImage.webp'); // 确保图片路径正确

// currentPrompt 与 Vuex 的绑定
const currentPrompt = computed<string>({
    get: () => store.getters['CImage/currentPrompt'], // 从 Vuex 获取当前提示词
    set: (value: string) => store.commit('CImage/setCurrentPrompt', value), // 提交更新提示词
});

// currentImage 与 Vuex 的绑定 (单个字符串)
const currentImage = computed<string>({
    get: () => store.getters['CImage/currentImage'] || '', // 如果为 null 或未定义，则返回空字符串
    set: (value: string) => store.commit('CImage/setCurrentImage', value), // 提交更新图片路径
});


// image_id 与 Vuex 的绑定
const image_id = computed<string>({
    get: () => store.getters['CImage/currentVideoId'], // 从 Vuex 获取当前图片 ID
    set: (value: string) => store.commit('CImage/setCurrentVideoId', value), // 提交更新图片 ID
});

// isGenerating 与 Vuex 的绑定
const isGenerating = computed<boolean>({
    get: () => store.getters['CImage/isGenerating'], // 从 Vuex 获取当前是否正在生成图片
    set: (value: boolean) => store.commit('CImage/setIsGenerating', value), // 提交更新是否正在生成图片
});

const uploadImage = computed<string>({
    get: () => store.getters['CImage/uploadImage'],
    set: (value: string) => store.commit('CImage/setUploadImage', value),
});

// const imagesToGenerateCount = computed<number>({
//     get: () => store.getters['CImage/imagesToGenerateCount'], // 从 Vuex 获取当前图片数量
//     set: (value: number) => store.commit('CImage/setImagesToGenerateCount', value), // 提交更新图片数量
// });


// 示例生成的图片列表
const generatedImages = computed(() => store.getters['CImage/imageList'])
console.log('generatedImages:', generatedImages.value);
console.log('generatedImages:', generatedImages.value.image_url);


const fileInput = ref<HTMLInputElement | null>(null); // 文件输入框的引用


function recolor() {
    currentPrompt.value = "Text layout design";
}


// 触发文件选择
const triggerFileInput = () => {
    if (fileInput.value) {
        fileInput.value.click();
    }
};

// 处理图片上传和预览
const handleImageUpload = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0]; // 获取选中的文件

    if (file) {
        try {
            // 读取图片文件
            const uploadedImage = await readFileAsDataURL(file);

            if (uploadedImage) {
                // 更新图片预览路径
                currentImage.value = uploadedImage; // 假设 currentImage 是你用来存储图片预览路径的变量
                console.log('上传成功，图片路径为:', uploadedImage);

                uploadImage.value = ''

                // 调用上传图片的方法
                await uploadImageToServer(file); // 调用上传方法
            } else {
                console.error('FileReader 读取失败');
            }
        } catch (error) {
            console.error('上传图片过程中发生错误:', error);
        }
    } else {
        console.error('未选择文件或文件无效');
    }
};

// 读取文件为 Base64
const readFileAsDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target?.result) {
                resolve(e.target.result as string);
            } else {
                reject('读取失败');
            }
        };
        reader.onerror = (e) => reject('FileReader 读取错误');
        reader.readAsDataURL(file);
    });
};

// 上传图片到服务器
const uploadImageToServer = async (file: File) => {
    try {
        await store.dispatch('CImage/uploadImageByAPI', file); // 调用上传方法
    } catch (error) {
        console.error('上传图片过程中发生错误:', error);
    }
};



function setImageCount(num: number) {
    store.commit('CImage/setImagesToGenerateCount', num);
}


// 控制弹窗显示
const showModal = ref(false);

function handleGenerateVideo() {
    // 提前检查 selectedVideo 和其他依赖项是否有效
    if (!currentImage.value || !currentPrompt.value) {
        alert('请上传图片并填写提示词！');
        return;
    }
    else if (uploadImage.value === '') {
        alert('图片的压缩上传未完成');
        return;
    }
    console.log('上传返回的地址：' + uploadImage.value);

    // console.log('生成图片的提示词:', currentPrompt.value);
    // console.log('生成图片的图片:', currentImage.value);
    // 显示确认弹窗
    showModal.value = true;
}

// 确认生成图片
function confirmGenerateVideo() {
    showModal.value = false; // 关闭弹窗

    const first_frame_image = currentImage.value;
    const prompt = currentPrompt.value;
    const id = image_id.value;

    //   console.log(prompt);
    //   console.log(first_frame_image);
    isGenerating.value = true; // 开始生成图片    

    // 调用 Vuex 的 generateVideo action
    store.dispatch('CImage/generateImage', {
        imagePrompt: prompt,
        image_id: id,
        first_frame_image: first_frame_image,
    })
        .then(() => {
            console.log("图片生成请求完成");
        })
        .catch((error) => {
            console.error("生成图片失败:", error);
        })
        .finally(() => {
            isGenerating.value = false;
        });
}

// 取消生成图片
function cancelGenerateVideo() {
    showModal.value = false; // 关闭弹窗
}

// 加载图片历史记录
onMounted(() => {
    store.dispatch('CImage/getAllImageByUser');
})

</script>


<style scoped lang="scss">
h1 {
    margin-left: 30px;
    color: white;
}


.separate {
    height: 10px;
}

.image-page {
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    padding: 15px;
    border-radius: 8px;
    width: 90%;
    margin: auto; // 水平居中

    .container {
        display: flex;
        gap: 10px;

    }

    .text-area,
    .image-output {
        background-color: white;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 300px;
        overflow-y: auto;
        max-height: 380px;

        h2 {
            margin-top: 0;
            font-size: 1.2em;
            margin-bottom: 10px;
        }
    }

    .text-area {
        width: 550px;
        position: relative;

        /* 父组件相对定位 */
        .upload-container {
            display: flex;
            align-items: center;
            gap: 20px;
            /* 控制上传按钮和图片之间的距离 */
            margin-left: 10px;
        }

        textarea {
            margin-top: 10px;
            width: 95%;
            height: 170px;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            resize: none;
        }

        .upload-img i {
            font-size: 25px;
            background-color: #007bff;
            /* 蓝色主题 */
            color: white;
            border: none;
            border-radius: 50%;
            padding: 6px;
            /* 统一的内边距 */
            cursor: pointer;
            transition: background-color 0.3s, transform 0.3s;
            /* 添加变换效果 */
        }

        .upload-img i:hover {
            background-color: #0056b3;
            transform: scale(1.05);
        }

        .upload-img {
            margin-top: 5px;
            /* 统一设置按钮的上间距 */
        }

        img {
            height: 100px;
        }

        .placeholder {
            background-color: rgb(126, 122, 122);
            /* 半透明黑色背景 */
            min-width: 160px;
            min-height: 100px;
            border-radius: 8px;
            color: white;
            /* 白色字体 */
            font-size: 15px;
            /* 让内容上下左右居中 */
            display: flex;
            justify-content: center;
            /* 水平居中 */
            align-items: center;
            /* 垂直居中 */
        }

        button {
            width: calc(100% - 20px);
            padding: 10px;
            background-color: #2196f3;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 1em;
            position: sticky;
            /* 使用 sticky 定位 */
            bottom: 0;
            /* 固定在底部 */
            left: 10px;
            z-index: 1;
            /* 确保按钮显示在其他内容之上 */
        }

        button:hover {
            background-color: #3b88db;
        }
    }

    .image-output {

        /* 启用换行 */
        position: relative;
        padding: 0;
        padding-top: 10px;
        /* 确保父容器是相对定位 */
        max-height: 400px;
        min-width: 340px;
        width: 700px;

        .image-list {
            margin-left: 10px;
            display: flex;
            /* 确保父容器使用 Flex 布局 */
            flex-wrap: wrap;
        }

        .image-container {
            flex: 1 1 calc(30% - 10px);
            /* 每个容器占父容器一半宽度，减去间距 */
            max-width: calc(30% - 10px);
            /* 确保宽度一致 */
            display: flex;
            flex-direction: column;
            /* 纵向排列内容（如果有额外内容） */
            gap: 10px;
            margin-bottom: 15px;
            /* 与下一行保持间距 */
            padding: 10px;
            border-radius: 8px;
            overflow: hidden;
        }

        img {
            width: 100%;
            /* 图片宽度占满容器 */
            border-radius: 4px;
        }
    }
}


.modal-overlay {
    position: absolute;
    /* 相对于父组件定位 */
    top: 50%;
    /* 垂直居中 */
    left: 50%;
    /* 水平居中 */
    transform: translate(-50%, -50%);
    /* 调整定位点到元素中心 */
    width: 100%;
    /* 适配父组件宽度 */
    height: 100%;
    /* 适配父组件高度 */
    background-color: rgba(0, 0, 0, 0.5);
    /* 半透明背景 */
    display: flex;
    /* 使用 Flex 布局 */
    justify-content: center;
    /* 内容水平居中 */
    align-items: center;
    /* 内容垂直居中 */
    z-index: 1000;
    /* 保证弹窗显示在其他内容之上 */
}

/* 弹窗容器 */
.modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    /* 可根据需要调整宽度 */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

/* 标题样式 */
.modal h4 {
    text-align: left;
    /* 将标题左对齐 */
    margin: 0;
    font-size: 18px;
    padding-bottom: 5px;
    /* 缩小标题和其他内容的间距 */
}

/* 正文内容样式 */
.modal p {
    margin: 0;
    font-size: 14px;
    padding: 5px 0;
    text-align: center;
    /* 让正文内容居中 */
}

/* 按钮容器 */
.button-group {
    display: flex;
    flex-direction: row;
    /* 按钮上下排列 */
    gap: 10px;
    /* 按钮之间的间距 */
    align-items: center;
    /* 按钮居中对齐 */
    margin-top: 10px;
}

/* 按钮样式 */
.modal button {
    font-size: 12px;
    /* 缩小按钮的字体 */
    padding: 6px 12px;
    /* 缩小按钮的尺寸 */
    width: 80%;
    /* 按钮宽度为父容器的80% */
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

/* 按钮悬停效果 */
.modal button:hover {
    background-color: #0056b3;
}

/* 第二个按钮的样式 */
.modal button:last-child {
    background-color: #6c757d;
}

.modal button:last-child:hover {
    background-color: #5a6268;
}

.quantity-section {
    margin-top: 20px;
    text-align: center;
}

.quantity-label {
    text-align: left;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
}

.quantity-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.quantity-buttons button {
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.quantity-buttons button:hover {
    background-color: #45a049;
}

.quantity-buttons button:active {
    background-color: #388e3c;
}

/* 添加选中按钮的样式 */
.quantity-buttons button.selected {
    background-color: #33509e;
    /* 更深的绿色 */
    border: 2px solid #1a3e8a;
    /* 添加边框 */
}
</style>