<template>

    <!-- 判断是否有权限 -->
    <div v-if="hasPermission" class="design">

        <h1> 设计功能还未开放，敬请期待 </h1>

    </div>

    <!-- 如果没有权限，显示无权限提示 -->
    <div v-else>
        <h1>无权访问该页面</h1>
    </div>
    
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';

// 使用 computed 来动态计算是否有权限
const hasPermission = computed(() => {
  try {
    // 从 localStorage 中获取 permissions，并解析为数组
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    // 判断是否包含 'create_creative' 权限
    return permissions.includes('ai_design');
  } catch (error) {
    console.error('解析 permissions 出错:', error);
    // 如果解析失败，默认没有权限
    return false;
  }
});


</script>

<style scoped lang="scss">

h1{
    color: white;
}

</style>