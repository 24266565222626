import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "image-page"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "text-area" }
const _hoisted_4 = { class: "upload-container" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "placeholder"
}
const _hoisted_7 = { class: "modal-overlay" }
const _hoisted_8 = { class: "image-output" }
const _hoisted_9 = { class: "image-list" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { key: 1 }

import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// 使用 Vuex store

export default /*@__PURE__*/_defineComponent({
  __name: 'ImageComponent',
  setup(__props) {

const store = useStore();

// 使用 computed 来动态计算是否有权限
const hasPermission = computed(() => {
    try {
        // 从 localStorage 中获取 permissions，并解析为数组
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        // 判断是否包含 'create_creative' 权限
        return permissions.includes('generate_image');
    } catch (error) {
        console.error('解析 permissions 出错:', error);
        // 如果解析失败，默认没有权限
        return false;
    }
});

// 默认占位图片路径
const noImage = require('@/assets/noImage.webp'); // 确保图片路径正确

// currentPrompt 与 Vuex 的绑定
const currentPrompt = computed<string>({
    get: () => store.getters['CImage/currentPrompt'], // 从 Vuex 获取当前提示词
    set: (value: string) => store.commit('CImage/setCurrentPrompt', value), // 提交更新提示词
});

// currentImage 与 Vuex 的绑定 (单个字符串)
const currentImage = computed<string>({
    get: () => store.getters['CImage/currentImage'] || '', // 如果为 null 或未定义，则返回空字符串
    set: (value: string) => store.commit('CImage/setCurrentImage', value), // 提交更新图片路径
});


// image_id 与 Vuex 的绑定
const image_id = computed<string>({
    get: () => store.getters['CImage/currentVideoId'], // 从 Vuex 获取当前图片 ID
    set: (value: string) => store.commit('CImage/setCurrentVideoId', value), // 提交更新图片 ID
});

// isGenerating 与 Vuex 的绑定
const isGenerating = computed<boolean>({
    get: () => store.getters['CImage/isGenerating'], // 从 Vuex 获取当前是否正在生成图片
    set: (value: boolean) => store.commit('CImage/setIsGenerating', value), // 提交更新是否正在生成图片
});

const uploadImage = computed<string>({
    get: () => store.getters['CImage/uploadImage'],
    set: (value: string) => store.commit('CImage/setUploadImage', value),
});

// const imagesToGenerateCount = computed<number>({
//     get: () => store.getters['CImage/imagesToGenerateCount'], // 从 Vuex 获取当前图片数量
//     set: (value: number) => store.commit('CImage/setImagesToGenerateCount', value), // 提交更新图片数量
// });


// 示例生成的图片列表
const generatedImages = computed(() => store.getters['CImage/imageList'])
console.log('generatedImages:', generatedImages.value);
console.log('generatedImages:', generatedImages.value.image_url);


const fileInput = ref<HTMLInputElement | null>(null); // 文件输入框的引用


function recolor() {
    currentPrompt.value = "Text layout design";
}


// 触发文件选择
const triggerFileInput = () => {
    if (fileInput.value) {
        fileInput.value.click();
    }
};

// 处理图片上传和预览
const handleImageUpload = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0]; // 获取选中的文件

    if (file) {
        try {
            // 读取图片文件
            const uploadedImage = await readFileAsDataURL(file);

            if (uploadedImage) {
                // 更新图片预览路径
                currentImage.value = uploadedImage; // 假设 currentImage 是你用来存储图片预览路径的变量
                console.log('上传成功，图片路径为:', uploadedImage);

                uploadImage.value = ''

                // 调用上传图片的方法
                await uploadImageToServer(file); // 调用上传方法
            } else {
                console.error('FileReader 读取失败');
            }
        } catch (error) {
            console.error('上传图片过程中发生错误:', error);
        }
    } else {
        console.error('未选择文件或文件无效');
    }
};

// 读取文件为 Base64
const readFileAsDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target?.result) {
                resolve(e.target.result as string);
            } else {
                reject('读取失败');
            }
        };
        reader.onerror = (e) => reject('FileReader 读取错误');
        reader.readAsDataURL(file);
    });
};

// 上传图片到服务器
const uploadImageToServer = async (file: File) => {
    try {
        await store.dispatch('CImage/uploadImageByAPI', file); // 调用上传方法
    } catch (error) {
        console.error('上传图片过程中发生错误:', error);
    }
};



function setImageCount(num: number) {
    store.commit('CImage/setImagesToGenerateCount', num);
}


// 控制弹窗显示
const showModal = ref(false);

function handleGenerateVideo() {
    // 提前检查 selectedVideo 和其他依赖项是否有效
    if (!currentImage.value || !currentPrompt.value) {
        alert('请上传图片并填写提示词！');
        return;
    }
    else if (uploadImage.value === '') {
        alert('图片的压缩上传未完成');
        return;
    }
    console.log('上传返回的地址：' + uploadImage.value);

    // console.log('生成图片的提示词:', currentPrompt.value);
    // console.log('生成图片的图片:', currentImage.value);
    // 显示确认弹窗
    showModal.value = true;
}

// 确认生成图片
function confirmGenerateVideo() {
    showModal.value = false; // 关闭弹窗

    const first_frame_image = currentImage.value;
    const prompt = currentPrompt.value;
    const id = image_id.value;

    //   console.log(prompt);
    //   console.log(first_frame_image);
    isGenerating.value = true; // 开始生成图片    

    // 调用 Vuex 的 generateVideo action
    store.dispatch('CImage/generateImage', {
        imagePrompt: prompt,
        image_id: id,
        first_frame_image: first_frame_image,
    })
        .then(() => {
            console.log("图片生成请求完成");
        })
        .catch((error) => {
            console.error("生成图片失败:", error);
        })
        .finally(() => {
            isGenerating.value = false;
        });
}

// 取消生成图片
function cancelGenerateVideo() {
    showModal.value = false; // 关闭弹窗
}

// 加载图片历史记录
onMounted(() => {
    store.dispatch('CImage/getAllImageByUser');
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "separate" }, null, -1)),
    (hasPermission.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[6] || (_cache[6] = _createElementVNode("h2", null, "图片参考图与提示词", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: "upload-img",
                  onClick: triggerFileInput
                }, [
                  _cache[1] || (_cache[1] = _createElementVNode("div", null, [
                    _createElementVNode("i", { class: "bi bi-box-arrow-up" })
                  ], -1)),
                  _createElementVNode("input", {
                    type: "file",
                    accept: "image/*",
                    ref_key: "fileInput",
                    ref: fileInput,
                    onChange: handleImageUpload,
                    style: {"display":"none"}
                  }, null, 544)
                ]),
                (currentImage.value)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: currentImage.value,
                      alt: "效果图",
                      class: "index-img",
                      crossorigin: "anonymous"
                    }, null, 8, _hoisted_5))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, " 等待上传 ")),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "quantity-label" }, "上传的图片需要压缩并保存在云端，请耐心等待上传完成的提示", -1))
              ]),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentPrompt).value = $event)),
                placeholder: "请输入图片提示词...(目前仅支持英文)"
              }, null, 512), [
                [_vModelText, currentPrompt.value]
              ]),
              _createElementVNode("button", { onClick: handleGenerateVideo }, "生成"),
              _withDirectives(_createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", { class: "modal" }, [
                  _cache[3] || (_cache[3] = _createElementVNode("h4", null, "生成图片确认", -1)),
                  _cache[4] || (_cache[4] = _createElementVNode("p", null, "每张图片的费用为¥0.25，", -1)),
                  _cache[5] || (_cache[5] = _createElementVNode("p", null, "您确定要生成图片吗？", -1)),
                  _createElementVNode("div", { class: "button-group" }, [
                    _createElementVNode("button", {
                      class: "confirm-button",
                      onClick: confirmGenerateVideo
                    }, "确认"),
                    _createElementVNode("button", {
                      class: "confirm-button",
                      onClick: cancelGenerateVideo
                    }, "取消")
                  ])
                ])
              ], 512), [
                [_vShow, showModal.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[7] || (_cache[7] = _createElementVNode("h2", null, "图片成品", -1)),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(generatedImages.value, (image) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: image.id,
                    class: "image-container"
                  }, [
                    _createElementVNode("img", {
                      src: image.image_url,
                      alt: "Generated Image",
                      crossorigin: "anonymous"
                    }, null, 8, _hoisted_10)
                  ]))
                }), 128))
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[8] || (_cache[8] = [
          _createElementVNode("h1", null, "无权访问该页面", -1)
        ])))
  ], 64))
}
}

})