import instance from './index'; // 引入封装的 axios 实例

// 查询用户生成的图片的 API
export const getAllImageByUser = async (userId: string): Promise<any> => {
    try {
        const response = await instance.get('/api/v2/get_all_images_by_user_id', {
            params: { user_id: userId },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching images:", error);
        throw error;
    }
};

// 查询图片生成状态的 API
export const queryImageGeneration = async (taskId: string, userId: string): Promise<any> => {
    try {
        const response = await instance.get('/api/v2/get_image_status', {
            params: { user_id: userId, generate_uuid: taskId },
        });
        return response.data;
    } catch (error) {
        console.error("Error querying image generation:", error);
        throw error;
    }
};

// 触发图片生成的 API
export const invokeImageGeneration = async (videoData: {
    user_id: string;
    username: string;
    reference_url: string;
    prompt: string;
    desired_effect: string;
}): Promise<any> => {
    try {
        const response = await instance.post('/api/v2/gennerate_image', videoData);
        return response.data;
    } catch (error) {
        console.error("Error invoking image generation:", error);
        throw error;
    }
};

// 上传图片的 API
export const uploadImage = async (formData: FormData): Promise<any> => {
    try {
        const response = await instance.post('/api/v2/upload-image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error uploading image:", error);
        throw error;
    }
};
