<template>
    <!-- <div class="material">
        <img src="@/assets/material.png" class="index-img" crossorigin="anonymous">
    </div> -->

    <div class="hotspot">

        <!-- 判断是否有权限 -->
        <div v-if="hasPermission" class="main">
            <HotspotMainView></HotspotMainView>
        </div>

        <div v-if="hasPermission" class="search">
            <HotspotSearchView></HotspotSearchView>
        </div>

        <!-- 如果没有权限，显示无权限提示 -->
        <div v-else>
            <h1>无权访问该页面</h1>
        </div>
    </div>



</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import HotspotMainView from './HotspotMainView.vue';
import HotspotSearchView from './HotspotSearchView.vue';

// 使用 computed 来动态计算是否有权限
const hasPermission = computed(() => {
  try {
    // 从 localStorage 中获取 permissions，并解析为数组
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    // 判断是否包含 'create_creative' 权限
    return permissions.includes('ai_hotpot');
  } catch (error) {
    console.error('解析 permissions 出错:', error);
    // 如果解析失败，默认没有权限
    return false;
  }
});

</script>

<style scoped lang="scss">

h1{
  margin-left: 30px;
  color: white;
}

.hotspot {
    margin: 0;
    padding: 0;
}

// .index-img{
//     width: 72%;
//     height: auto;
// }

.main {
    width: 100%;
    text-align: center; /* 水平居中 */
}


.search {
    width: 100%;
    text-align: center; /* 水平居中 */
}
</style>