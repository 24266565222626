<template>
  <div class="storyboard">

    <!-- 判断是否有权限 -->
    <div v-if="hasPermission" class="tool">
      <StoryboardTool />
    </div>
    <div v-if="hasPermission" class="storyboard">
      <StoryboardMain />
    </div>

    <!-- 如果没有权限，显示无权限提示 -->
    <div v-else>
      <h1>无权访问该页面</h1>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { ref,computed, onActivated, onDeactivated } from 'vue';
import StoryboardTool from './StoryboardTool.vue';
import StoryboardMain from './StoryboardMain.vue';


// 使用 computed 来动态计算是否有权限
const hasPermission = computed(() => {
  try {
    // 从 localStorage 中获取 permissions，并解析为数组
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    // 判断是否包含 'create_creative' 权限
    return permissions.includes('use_storyboard');
  } catch (error) {
    console.error('解析 permissions 出错:', error);
    // 如果解析失败，默认没有权限
    return false;
  }
});


// 生命周期钩子
onActivated(() => {
  console.log('StoryboardView 被激活');
});

onDeactivated(() => {
  console.log('StoryboardView 被缓存');
});

</script>

<style scoped lang="scss">

h1{
  margin-left: 30px;
  color: white;
}

.storyboard {
  display: flex;
}

.tool {
  flex: 0.9;
  /* 占1份宽度 */
  border-right: 1px solid #3a3a4d;
  /* 边框分隔线 */
  border-radius: 10px;
}

.main {
  flex: 9.1;
  /* 占9份宽度 */
  max-width: 100%;
  background-color: #1e1e2f;
  /* 示例背景色 */
}
</style>
