<template>

    <!-- 判断是否有权限 -->
    <div v-if="hasPermission" class="main-search">
        <!-- 顶部搜索栏 -->
        <div class="search">
            <div class="search-bar">
                <input type="text" placeholder="请输入搜索内容" />
                <div class="dropdown-container">
                    <div class="dropdown" @click="toggleDropdown">
                        {{ selectedOption }}
                    </div>
                    <div v-if="dropdownVisible" class="dropdown-menu">
                        <div class="dropdown-item" @click="selectOption('搜全部')">全部</div>
                        <div class="dropdown-item" @click="selectOption('搜视频')">视频</div>
                        <div class="dropdown-item" @click="selectOption('搜音乐')">音乐</div>
                        <div class="dropdown-item" @click="selectOption('搜实拍图')">实拍图</div>
                        <div class="dropdown-item" @click="selectOption('搜设计图')">设计图</div>
                    </div>
                </div>
                <button>搜索</button>
            </div>
        </div>

        <!-- 分类导航 -->
        <div class="categories">
            <button v-for="(category, index) in categories" :key="index"
                :class="{ active: selectedCategory === category }" @click="selectCategory(category)">
                {{ category }}
            </button>
        </div>

        <!-- 图片瀑布流布局 -->
        <div ref="masonryContainer" class="masonry-grid">
            <div class="masonry-item" v-for="(image, index) in filteredImages" :key="index">
                <img v-lazy="image.src" :alt="image.alt" @load="onImageLoad" crossorigin="anonymous" />
            </div>
        </div>
    </div>

    <!-- 如果没有权限，显示无权限提示 -->
    <div v-else>
        
    </div>

</template>



<script lang="ts" setup>
import { ref, computed, onMounted, nextTick } from 'vue';
const Masonry = require('masonry-layout') as any;

// 使用 computed 来动态计算是否有权限
const hasPermission = computed(() => {
  try {
    // 从 localStorage 中获取 permissions，并解析为数组
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    // 判断是否包含 'create_creative' 权限
    return permissions.includes('ai_search');
  } catch (error) {
    console.error('解析 permissions 出错:', error);
    // 如果解析失败，默认没有权限
    return false;
  }
});

const masonryContainer = ref(null);
const categories = ref(['全部', '视频', '音乐', '实拍图', '设计图']);
const selectedCategory = ref('全部');
const selectedOption = ref('搜全部');
const dropdownVisible = ref(false);


// 示例图片数据，按类别分类
const allImages = ref([
    { src: require('@/assets/meterial/all/img-1.jpg'), alt: 'Image 1', category: '视频', loaded: true },
    { src: require('@/assets/meterial/all/img-2.jpg'), alt: 'Image 2', category: '视频', loaded: true },
    { src: require('@/assets/meterial/all/img-3.jpg'), alt: 'Image 3', category: '音乐', loaded: true },
    { src: require('@/assets/meterial/all/img-4.jpg'), alt: 'Image 4', category: '实拍图', loaded: true },
    { src: require('@/assets/meterial/all/img-5.jpg'), alt: 'Image 5', category: '设计图', loaded: true },
    { src: require('@/assets/meterial/all/img-6.jpg'), alt: 'Image 6', category: '音乐', loaded: true },
    { src: require('@/assets/meterial/all/img-7.jpg'), alt: 'Image 7', category: '实拍图', loaded: false },
    { src: require('@/assets/meterial/all/img-8.jpg'), alt: 'Image 8', category: '设计图', loaded: false },
    { src: require('@/assets/meterial/all/img-9.jpg'), alt: 'Image 9', category: '音乐', loaded: false },
    { src: require('@/assets/meterial/all/img-10.jpg'), alt: 'Image 10', category: '实拍图', loaded: false },
    { src: require('@/assets/meterial/all/img-11.jpg'), alt: 'Image 11', category: '设计图', loaded: false },
    { src: require('@/assets/meterial/all/img-12.jpg'), alt: 'Image 12', category: '视频', loaded: false },
    { src: require('@/assets/meterial/all/img-13.jpg'), alt: 'Image 13', category: '实拍图', loaded: false },

]);

const filteredImages = computed(() => {
    return selectedCategory.value === '全部'
        ? allImages.value
        : allImages.value.filter(image => image.category === selectedCategory.value);
});

const toggleDropdown = () => {
    dropdownVisible.value = !dropdownVisible.value;
};

const selectOption = (option: string) => {
    selectedOption.value = option;
    dropdownVisible.value = false;
};

const selectCategory = (category: string) => {
    selectedCategory.value = category;
};

const initMasonry = async () => {
    await nextTick();
    new Masonry(masonryContainer.value, {
        itemSelector: '.masonry-item',
        columnWidth: '.masonry-item',
        percentPosition: true,
        gutter: 10
    });
};


// 确保图片加载完成后触发 Masonry 布局刷新
const onImageLoad = async () => {
    await nextTick();
    initMasonry();
};

</script>

<style scoped lang="scss">
.main-search {
    background-color: #181818;
    color: white;
    padding: 20px;
    align-items: center;

    .search {
        width: 480px;
        margin: 0 auto;
    }

    .search-bar {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        position: relative;
        border-radius: 10px;
        padding: 5px;
        background-color: #2a2a2a;

        input {
            padding: 8px;
            font-size: 12px;
            width: 300px;
            border: 0;
            border-radius: 4px;
            background-color: #2a2a2a;
            color: white;
        }

        button {
            padding: 6px 12px !important;
            font-size: 12px;
            cursor: pointer;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
        }

        .dropdown-container {
            position: relative;
            margin-right: 8px;

            .dropdown {
                background-color: #2a2a2a;
                color: white;
                padding: 6px 12px;
                font-size: 12px;
                cursor: pointer;
                border: 0;
                text-align: center;
                user-select: none;
            }

            .dropdown-menu {
                position: absolute;
                bottom: 100%;
                left: 0;
                background-color: #333;
                border: 0;
                border-radius: 4px;
                overflow: hidden;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                z-index: 10;

                .dropdown-item {
                    padding: 6px 12px;
                    font-size: 12px;
                    color: white;
                    cursor: pointer;
                    transition: background-color 0.3s;

                    &:hover {
                        background-color: #007bff;
                    }
                }
            }
        }
    }

    .categories {
        display: flex;
        justify-content: center;
        gap: 16px;
        margin-bottom: 20px;

        button {
            background: none;
            color: white;
            border: none;
            font-size: 16px;
            cursor: pointer;
            padding: 8px 16px;
            transition: color 0.3s ease;
        }

        .active {
            color: #007bff;
            border-bottom: 2px solid #007bff;
        }

        button:hover {
            color: #007bff;
        }
    }


}

.masonry-grid {
    display: flex;
    justify-content: center;
    /* 将内容居中 */
    max-width: 1200px;
    /* 可选：设置最大宽度以避免内容过宽 */
    margin: 0 auto;
    /* 将整个布局容器居中 */
}

.masonry-item {
    margin: 10px;
    /* 设置上下和左右的间距，使其均匀 */
    width: 200px;
    border-radius: 8px;
    background-color: #333;

    img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
}
</style>
