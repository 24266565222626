<template>
  <div class="plan">
    <!-- 判断是否有权限 -->
    <div v-if="hasPermission" class="video-type">
      <VideoTypeSelectionView />
    </div>
    <div v-if="hasPermission" class="plan-main">
      <PlanwritingMainView />
    </div>
    <div v-if="hasPermission" class="video-setting">
      <PlanSetting />
    </div>

    <!-- 如果没有权限，显示无权限提示 -->
    <div v-else>
      <h1>无权访问该页面</h1>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onActivated, onDeactivated } from 'vue';
import { useStore } from 'vuex';
import VideoTypeSelectionView from '@/views/Plan/VideoTypeSelection.vue';
import PlanwritingMainView from '@/views/Plan/PlanMainView.vue';
import PlanSetting from '@/views/Plan/PlanSetting.vue';

// 获取 Vuex 状态中的 permissions
const store = useStore();

// 使用 computed 来动态计算是否有权限
const hasPermission = computed(() => {
  try {
    // 从 localStorage 中获取 permissions，并解析为数组
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    // 判断是否包含 'create_creative' 权限
    return permissions.includes('create_creative');
  } catch (error) {
    console.error('解析 permissions 出错:', error);
    // 如果解析失败，默认没有权限
    return false;
  }
});

// 生命周期钩子
onActivated(() => {
  console.log('planView 被激活');
});

onDeactivated(() => {
  console.log('planView 被缓存');
});
</script>


<style scoped lang="scss">

h1{
  margin-left: 30px;
  color: white;
}

.plan {
  display: flex;
  /* 使用 flex 布局 */
  min-height: 100%;
  /* 页面全屏高度 */
  background-color: #101115;
}

.video-type {
  flex: 1;
  margin: 5px;
  /* 设置左右间距 */
  display: flex;
  justify-content: center;
  /* 水平居中对齐 */
  align-items: center;
  /* 垂直居中对齐 */
  background-color: #1a1b20;
  /* 示例背景色 */
  border-radius: 8px;
}

.plan-main {
  flex: 2;
  margin: 5px;
  /* 设置左右间距 */
  display: flex;
  justify-content: center;
  /* 水平居中对齐 */
  background-color: #1a1b20;
  /* 示例背景色 */
  border-radius: 8px;
}

.video-setting {
  flex: 1;
  margin: 5px;
  display: flex;
  justify-content: center;
  /* 水平居中对齐 */
  background-color: #1a1b20;
  /* 示例背景色 */
  border-radius: 8px;
}
</style>