import instance from './index'; // 引入封装的 axios 实例

export const userLogin = async (username: string, password: string): Promise<any> => {
    try {
        // 使用 data 发送请求体中的数据
        const response = await instance.post('/api/login', {
            username: username,
            password: password
        });
        return response.data;
    } catch (error) {
        console.error("Error in userLogin status:", error);
        throw error; // 抛出错误，以便在调用时处理
    }
};