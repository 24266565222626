import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "login-box" }
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = { class: "input-group" }

import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axios from 'axios';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLogin',
  setup(__props) {

const store = useStore();
const router = useRouter();

const username = ref<string>('');
const password = ref<string>('');

const handleSubmit = async () => {
  try {
    // 确保传递的 username 和 password 是字符串类型，并且通过 .value 访问它们的实际值
    if (typeof username.value !== 'string' || typeof password.value !== 'string') {
      throw new Error('用户名或密码必须是字符串');
    }

    // 调用 Vuex 的登录方法，传递 username.value 和 password.value
    const response = await store.dispatch('auth/login', {
      username: username.value,
      password: password.value
    });

    if (response && response.success) {
      // 登录成功，跳转到计划页面
      router.push({ path: '/plan' });
    } else {
      // 登录失败
      console.error('登录失败');
      alert('用户名或密码错误');
    }
  } catch (error) {
    console.error('请求错误:', error);
    alert('登录请求失败，请稍后再试。');
  }
};



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "avatar" }, null, -1)),
      _createElementVNode("form", {
        onSubmit: _withModifiers(handleSubmit, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("label", { for: "username" }, "用户名", -1)),
          _withDirectives(_createElementVNode("input", {
            "data-v-5afd2294": "",
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((username).value = $event)),
            id: "username",
            required: "",
            autocomplete: "username"
          }, null, 512), [
            [_vModelText, username.value]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("label", { for: "password" }, "密码", -1)),
          _withDirectives(_createElementVNode("input", {
            "data-v-5afd2294": "",
            type: "password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
            id: "password",
            required: "",
            autocomplete: "current-password"
          }, null, 512), [
            [_vModelText, password.value]
          ])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("button", { type: "submit" }, "登录", -1))
      ], 32)
    ])
  ]))
}
}

})