import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "main-search"
}
const _hoisted_2 = { class: "search" }
const _hoisted_3 = { class: "search-bar" }
const _hoisted_4 = { class: "dropdown-container" }
const _hoisted_5 = {
  key: 0,
  class: "dropdown-menu"
}
const _hoisted_6 = { class: "categories" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["alt"]
const _hoisted_9 = { key: 1 }

import { ref, computed, onMounted, nextTick } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'MaterialSearchView',
  setup(__props) {

const Masonry = require('masonry-layout') as any;

// 使用 computed 来动态计算是否有权限
const hasPermission = computed(() => {
  try {
    // 从 localStorage 中获取 permissions，并解析为数组
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    // 判断是否包含 'create_creative' 权限
    return permissions.includes('ai_search');
  } catch (error) {
    console.error('解析 permissions 出错:', error);
    // 如果解析失败，默认没有权限
    return false;
  }
});

const masonryContainer = ref(null);
const categories = ref(['全部', '视频', '音乐', '实拍图', '设计图']);
const selectedCategory = ref('全部');
const selectedOption = ref('搜全部');
const dropdownVisible = ref(false);


// 示例图片数据，按类别分类
const allImages = ref([
    { src: require('@/assets/meterial/all/img-1.jpg'), alt: 'Image 1', category: '视频', loaded: true },
    { src: require('@/assets/meterial/all/img-2.jpg'), alt: 'Image 2', category: '视频', loaded: true },
    { src: require('@/assets/meterial/all/img-3.jpg'), alt: 'Image 3', category: '音乐', loaded: true },
    { src: require('@/assets/meterial/all/img-4.jpg'), alt: 'Image 4', category: '实拍图', loaded: true },
    { src: require('@/assets/meterial/all/img-5.jpg'), alt: 'Image 5', category: '设计图', loaded: true },
    { src: require('@/assets/meterial/all/img-6.jpg'), alt: 'Image 6', category: '音乐', loaded: true },
    { src: require('@/assets/meterial/all/img-7.jpg'), alt: 'Image 7', category: '实拍图', loaded: false },
    { src: require('@/assets/meterial/all/img-8.jpg'), alt: 'Image 8', category: '设计图', loaded: false },
    { src: require('@/assets/meterial/all/img-9.jpg'), alt: 'Image 9', category: '音乐', loaded: false },
    { src: require('@/assets/meterial/all/img-10.jpg'), alt: 'Image 10', category: '实拍图', loaded: false },
    { src: require('@/assets/meterial/all/img-11.jpg'), alt: 'Image 11', category: '设计图', loaded: false },
    { src: require('@/assets/meterial/all/img-12.jpg'), alt: 'Image 12', category: '视频', loaded: false },
    { src: require('@/assets/meterial/all/img-13.jpg'), alt: 'Image 13', category: '实拍图', loaded: false },

]);

const filteredImages = computed(() => {
    return selectedCategory.value === '全部'
        ? allImages.value
        : allImages.value.filter(image => image.category === selectedCategory.value);
});

const toggleDropdown = () => {
    dropdownVisible.value = !dropdownVisible.value;
};

const selectOption = (option: string) => {
    selectedOption.value = option;
    dropdownVisible.value = false;
};

const selectCategory = (category: string) => {
    selectedCategory.value = category;
};

const initMasonry = async () => {
    await nextTick();
    new Masonry(masonryContainer.value, {
        itemSelector: '.masonry-item',
        columnWidth: '.masonry-item',
        percentPosition: true,
        gutter: 10
    });
};


// 确保图片加载完成后触发 Masonry 布局刷新
const onImageLoad = async () => {
    await nextTick();
    initMasonry();
};


return (_ctx: any,_cache: any) => {
  const _directive_lazy = _resolveDirective("lazy")!

  return (hasPermission.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[5] || (_cache[5] = _createElementVNode("input", {
              type: "text",
              placeholder: "请输入搜索内容"
            }, null, -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: "dropdown",
                onClick: toggleDropdown
              }, _toDisplayString(selectedOption.value), 1),
              (dropdownVisible.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", {
                      class: "dropdown-item",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (selectOption('搜全部')))
                    }, "全部"),
                    _createElementVNode("div", {
                      class: "dropdown-item",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (selectOption('搜视频')))
                    }, "视频"),
                    _createElementVNode("div", {
                      class: "dropdown-item",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (selectOption('搜音乐')))
                    }, "音乐"),
                    _createElementVNode("div", {
                      class: "dropdown-item",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (selectOption('搜实拍图')))
                    }, "实拍图"),
                    _createElementVNode("div", {
                      class: "dropdown-item",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (selectOption('搜设计图')))
                    }, "设计图")
                  ]))
                : _createCommentVNode("", true)
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("button", null, "搜索", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categories.value, (category, index) => {
            return (_openBlock(), _createElementBlock("button", {
              key: index,
              class: _normalizeClass({ active: selectedCategory.value === category }),
              onClick: ($event: any) => (selectCategory(category))
            }, _toDisplayString(category), 11, _hoisted_7))
          }), 128))
        ]),
        _createElementVNode("div", {
          ref_key: "masonryContainer",
          ref: masonryContainer,
          class: "masonry-grid"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredImages.value, (image, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "masonry-item",
              key: index
            }, [
              _withDirectives(_createElementVNode("img", {
                alt: image.alt,
                onLoad: onImageLoad,
                crossorigin: "anonymous"
              }, null, 40, _hoisted_8), [
                [_directive_lazy, image.src]
              ])
            ]))
          }), 128))
        ], 512)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_9))
}
}

})