import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "music"
}
const _hoisted_2 = { key: 1 }

import { ref, computed, onMounted } from 'vue';

// 使用 computed 来动态计算是否有权限

export default /*@__PURE__*/_defineComponent({
  __name: 'MusicComponent',
  setup(__props) {

const hasPermission = computed(() => {
  try {
    // 从 localStorage 中获取 permissions，并解析为数组
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    // 判断是否包含 'create_creative' 权限
    return permissions.includes('ai_music_score');
  } catch (error) {
    console.error('解析 permissions 出错:', error);
    // 如果解析失败，默认没有权限
    return false;
  }
});


return (_ctx: any,_cache: any) => {
  return (hasPermission.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("h1", null, " 配乐功能还未开放，敬请期待 ", -1)
      ])))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
        _createElementVNode("h1", null, "无权访问该页面", -1)
      ])))
}
}

})