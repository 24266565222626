// /store/modules/auth.ts
import { Commit } from 'vuex';
import { userLogin } from '@/api/user';

interface State {
    token: string | null;
    username: string | null;
    userID: string | null; // 新增 userID
    roles: string[];  // 新增 roles 字段
    permissions: string[];  // 新增 permissions 字段
}

const state: State = {
    token: null,
    username: null,
    userID: null, // 新增 userID
    roles: [],  // 初始化 roles
    permissions: [],  // 初始化 permissions
};

const mutations = {
    setToken(state: State, token: string) {
        state.token = token;
    },
    setUsername(state: State, username: string) {
        state.username = username;
    },
    setUserID(state: State, userID: string) { // 新增 setUserID
        state.userID = userID;
    },
    clearToken(state: State) {
        state.token = null;
        state.username = null;
        state.userID = null; // 清空 userID
        state.roles = [];  // 清空 roles
        state.permissions = [];  // 清空 permissions
    },
    setRoles(state: State, roles: string[]) {  // 新增 setRoles
        state.roles = roles;
    },
    setPermissions(state: State, permissions: string[]) {  // 新增 setPermissions
        state.permissions = permissions;
    },
    setUser(state: State, userData: any) {
        state.token = userData.token;
        state.username = userData.username;
        state.userID = userData.userID;
        state.roles = userData.roles || [];  // 确保 roles 字段有值
        state.permissions = userData.permissions || [];  // 确保 permissions 字段有值
    },
};

const actions = {

    async login({ commit }: { commit: Commit }, { username, password }: { username: string; password: string }) {
        try {

            // 打印传入的 username 和 password
            console.log('Received username:', username);  // 这里应该打印出 username 和 password
            console.log('Received password:', password);

            // 调用 API 进行登录
            const response = await userLogin(username, password);  // 这里调用登录接口
            console.log('Login response:', response);
            if (response.token) {
                // 登录成功，保存用户信息
                commit("setUser", {
                    token: response.token,
                    username: response.username,
                    userID: response.userID,
                    roles: response.roles,
                    permissions: response.permissions,
                });
                // 保存到本地存储
                localStorage.setItem('token', response.token);
                localStorage.setItem('username', username);
                localStorage.setItem('userID', response.userID);
                localStorage.setItem('roles', response.roles);
                localStorage.setItem('permissions', JSON.stringify(response.permissions));
                // 返回成功标志

                

                return { success: true };
            } else {
                // 如果接口返回的结果没有 token，则视为登录失败
                return { success: false };
            }
        } catch (error) {
            console.error('登录请求失败:', error);
            return { success: false };  // 返回失败
        }
    },

    logout({ commit }: { commit: Commit }) {
        commit('setToken', null);
        commit('setUsername', null);
        commit('setUserID', null);
        commit('setRoles', []);
        commit('setPermissions', []);
        // 从本地存储中删除
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('userID');
        localStorage.removeItem('roles');
        localStorage.removeItem('permissions');
    },
    initializeStore({ commit }: { commit: Commit }) {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const userID = localStorage.getItem('userID');
        if (token) {
            commit('setToken', token);
            commit('setUsername', username || '');
            commit('setUserID', userID || ''); // 在初始化时设置 userID
        }
    },

};

const getters = {
    token: (state: State) => state.token,
    username: (state: State) => state.username,
    userID: (state: State) => state.userID // 新增 userID getter
};

export default {
    namespaced: true, // 启用命名空间
    state,
    mutations,
    actions,
    getters,
};
