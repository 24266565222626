import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "video-page"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "text-area" }
const _hoisted_4 = { class: "upload-container" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "placeholder"
}
const _hoisted_7 = { class: "modal-overlay" }
const _hoisted_8 = { class: "video-output" }
const _hoisted_9 = { class: "video-list" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { key: 1 }

import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// 使用 Vuex store

export default /*@__PURE__*/_defineComponent({
  __name: 'VideoComponent',
  setup(__props) {

const store = useStore();

// 使用 computed 来动态计算是否有权限
const hasPermission = computed(() => {
  try {
    // 从 localStorage 中获取 permissions，并解析为数组
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    // 判断是否包含 'create_creative' 权限
    return permissions.includes('generate_video');
  } catch (error) {
    console.error('解析 permissions 出错:', error);
    // 如果解析失败，默认没有权限
    return false;
  }
});

// 默认占位图片路径
const noImage = require('@/assets/noImage.webp'); // 确保图片路径正确

// currentPrompt 与 Vuex 的绑定
const currentPrompt = computed<string>({
    get: () => store.getters['Cvideo/currentPrompt'], // 从 Vuex 获取当前提示词
    set: (value: string) => store.commit('Cvideo/setCurrentPrompt', value), // 提交更新提示词
});

// currentImage 与 Vuex 的绑定 (单个字符串)
const currentImage = computed<string>({
    get: () => store.getters['Cvideo/currentImage'] || '', // 如果为 null 或未定义，则返回空字符串
    set: (value: string) => store.commit('Cvideo/setCurrentImage', value), // 提交更新图片路径
});


// video_id 与 Vuex 的绑定
const video_id = computed<string>({
    get: () => store.getters['Cvideo/currentVideoId'], // 从 Vuex 获取当前视频 ID
    set: (value: string) => store.commit('Cvideo/setCurrentVideoId', value), // 提交更新视频 ID
});

// isGenerating 与 Vuex 的绑定
const isGenerating = computed<boolean>({
    get: () => store.getters['Cvideo/isGenerating'], // 从 Vuex 获取当前是否正在生成视频
    set: (value: boolean) => store.commit('Cvideo/setIsGenerating', value), // 提交更新是否正在生成视频
});

// 获取当前用户ID
const userId = computed(() => store.getters['auth/userID']);

// 示例生成的视频列表
const generatedVideos = computed(() => store.getters['Cvideo/videoList'])

const fileInput = ref<HTMLInputElement | null>(null); // 文件输入框的引用

// 触发文件选择
const triggerFileInput = () => {
    if (fileInput.value) {
        fileInput.value.click();
    }
};

// 处理图片上传
const handleImageUpload = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0]; // 获取选中的文件

    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const uploadedImage = e.target?.result as string;
            if (uploadedImage) {
                currentImage.value = uploadedImage; // 更新图片路径
                // console.log('上传成功，图片路径为');
            } else {
                console.error('FileReader 读取失败');
            }
        };
        reader.onerror = (e) => {
            console.error('FileReader 读取错误:', e);
        };
        reader.readAsDataURL(file); // 读取文件为 Base64
    } else {
        console.error('未选择文件或文件无效');
    }
};




// 控制弹窗显示
const showModal = ref(false);

function handleGenerateVideo() {
    // 提前检查 selectedVideo 和其他依赖项是否有效
    if (!currentImage.value || !currentPrompt.value) {
        alert('请上传图片并填写提示词！');
        return;
    }
    // console.log('生成视频的提示词:', currentPrompt.value);
    // console.log('生成视频的图片:', currentImage.value);
    // 显示确认弹窗
    showModal.value = true;
}

// 确认生成视频
function confirmGenerateVideo() {
    showModal.value = false; // 关闭弹窗

    const first_frame_image = currentImage.value;
    const prompt = currentPrompt.value;
    const id = video_id.value;

    //   console.log(prompt);
    //   console.log(first_frame_image);
    isGenerating.value = true; // 开始生成视频    

    // 调用 Vuex 的 generateVideo action
    store.dispatch('Cvideo/generateVideo', {
        videoPrompt: prompt,
        video_id: id,
        first_frame_image: first_frame_image,
    })
        .then(() => {
            console.log("视频生成请求完成");
        })
        .catch((error) => {
            console.error("生成视频失败:", error);
        })
        .finally(() => {
            isGenerating.value = false;
        });
}

// 取消生成视频
function cancelGenerateVideo() {
    showModal.value = false; // 关闭弹窗
}

// 加载视频历史记录
onMounted(() => {
    store.dispatch('Cvideo/getAllVideoByUser');
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "separate" }, null, -1)),
    (hasPermission.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[5] || (_cache[5] = _createElementVNode("h2", null, "视频参考图与提示词", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: "upload-img",
                  onClick: triggerFileInput
                }, [
                  _cache[1] || (_cache[1] = _createElementVNode("div", null, [
                    _createElementVNode("i", { class: "bi bi-box-arrow-up" })
                  ], -1)),
                  _createElementVNode("input", {
                    type: "file",
                    accept: "image/*",
                    ref_key: "fileInput",
                    ref: fileInput,
                    onChange: handleImageUpload,
                    style: {"display":"none"}
                  }, null, 544)
                ]),
                (currentImage.value)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: currentImage.value,
                      alt: "效果图",
                      class: "index-img",
                      crossorigin: "anonymous"
                    }, null, 8, _hoisted_5))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, " 等待上传 "))
              ]),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentPrompt).value = $event)),
                placeholder: "请输入视频提示词..."
              }, null, 512), [
                [_vModelText, currentPrompt.value]
              ]),
              _createElementVNode("button", { onClick: handleGenerateVideo }, "生成"),
              _withDirectives(_createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", { class: "modal" }, [
                  _cache[2] || (_cache[2] = _createElementVNode("h4", null, "生成视频确认", -1)),
                  _cache[3] || (_cache[3] = _createElementVNode("p", null, "每条视频的费用为¥3，", -1)),
                  _cache[4] || (_cache[4] = _createElementVNode("p", null, "您确定要生成视频吗？", -1)),
                  _createElementVNode("div", { class: "button-group" }, [
                    _createElementVNode("button", { onClick: confirmGenerateVideo }, "确认"),
                    _createElementVNode("button", { onClick: cancelGenerateVideo }, "取消")
                  ])
                ])
              ], 512), [
                [_vShow, showModal.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[6] || (_cache[6] = _createElementVNode("h2", null, "视频成品", -1)),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(generatedVideos.value, (video) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: video.id,
                    class: "video-container"
                  }, [
                    _createElementVNode("video", {
                      src: video.video_blob_url,
                      controls: "",
                      crossorigin: "anonymous"
                    }, null, 8, _hoisted_10)
                  ]))
                }), 128))
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[7] || (_cache[7] = [
          _createElementVNode("h1", null, "无权访问该页面", -1)
        ])))
  ], 64))
}
}

})