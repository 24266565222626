import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "video-editing"
}
const _hoisted_2 = { class: "editor-container" }
const _hoisted_3 = { class: "video-player-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "video-controls" }
const _hoisted_6 = { class: "edit-page" }
const _hoisted_7 = { id: "time-scale" }
const _hoisted_8 = {
  id: "timeline-track",
  class: "timeline-track"
}
const _hoisted_9 = { id: "timeline-container" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "audio-track" }
const _hoisted_13 = { class: "subtitle-track" }
const _hoisted_14 = { class: "details" }
const _hoisted_15 = { key: 1 }

import CameraDetails from './CameraDetails.vue';
import { ref, computed, onMounted, onActivated, onDeactivated, watch, onUnmounted } from 'vue';
import { useStore } from 'vuex';

// 获取 Vuex store 实例 

export default /*@__PURE__*/_defineComponent({
  __name: 'VideoEditingView',
  setup(__props) {

const store = useStore();

// 使用 computed 来动态计算是否有权限
const hasPermission = computed(() => {
  try {
    // 从 localStorage 中获取 permissions，并解析为数组
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    // 判断是否包含 'create_creative' 权限
    return permissions.includes('edit_video');
  } catch (error) {
    console.error('解析 permissions 出错:', error);
    // 如果解析失败，默认没有权限
    return false;
  }
});

// 使用 `ViewEdit` 模块的状态
const videoSegments = computed(() => store.state.ViewEdit.videoSegments);

// 获取 Vuex 中的 combinedVideoUrl
const combinedVideoUrl = computed(() => store.state.ViewEdit.combinedVideoUrl);

const player = ref<HTMLVideoElement | null>(null);

const timelineContainer = ref<HTMLElement | null>(null);

// 获取 Vuex 状态中的 isPlaying
const isPlaying = computed(() => store.state.ViewEdit.isPlaying);

// 从 Vuex 获取 timeMarks
const timeMarks = computed(() => store.state.ViewEdit.timeMarks);


// 从 Vuex 中获取 `showDetails` 的状态
const showDetails = computed({
  get: () => store.state.ViewEdit.isShowDetails,
  set: (value) => store.commit('ViewEdit/setIsShowDetails', value)
});

// 选择视频段的函数
const selectSegment = (videoId: string) => {
  // 获取当前 `selectedVideo` 的值进行判断
  const currentSelectedVideo = store.state.ViewEdit.selectedVideo;
  console.log('currentSelectedVideo:', currentSelectedVideo);

  if (currentSelectedVideo === videoId) {
    // 如果点击的是相同视频段，切换 `showDetails` 状态
    showDetails.value = !showDetails.value;
    console.log("改变showDetails的值")
  } else {
    // 如果点击的是不同视频段，更新 `selectedVideo` 并显示 `CameraDetails`
    store.commit('ViewEdit/setSelectedVideo', videoId);
    showDetails.value = false;
  }
};

// 清除选择的函数
const clearSelection = () => {
  store.commit('ViewEdit/clearSelection');
};

// 全局点击处理
const handleClickOutside = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  // 检查点击的目标是否在 `timeline-container` 或 `details` 内部
  if (
    !target.closest('#timeline-container') &&
    !target.closest('.details')
  ) {
    clearSelection(); // 点击外部区域时清除选择
  }
};

// 播放指针位置（百分比）
const playbackPosition = computed(() => {
  const playerElement = player.value;
  const currentTime = store.state.ViewEdit.currentTime;
  const duration = playerElement?.duration || 0;

  // 计算并返回百分比位置
  return duration ? (currentTime / duration) * 100 : 0;
});


// 拖动相关状态
const isDragging = ref(false);
const currentTime = ref(0); // 用于保存当前播放时间
const draggedPosition = ref(0); // 拖动时的播放指针位置（百分比）

// 指针的显示位置：拖动时显示 `draggedPosition`，否则显示 `playbackPosition`
const pointerPosition = computed(() => (isDragging.value ? draggedPosition.value : playbackPosition.value));

// 开始拖动
const startDragging = (event: MouseEvent) => {
  if (!player.value || player.value.readyState < 1) return;

  // 记录拖动前的播放状态并暂停
  store.commit('ViewEdit/setPlayingState', !player.value.paused);
  player.value.pause();

  isDragging.value = true;
  document.addEventListener('mousemove', onDragging);
  document.addEventListener('mouseup', stopDragging);
};

// 拖动时更新指针位置
const onDragging = (event: MouseEvent) => {
  if (!isDragging.value || !timelineContainer.value || !player.value) return;

  // 计算拖动位置的百分比
  const rect = timelineContainer.value.getBoundingClientRect();
  const offsetX = event.clientX - rect.left;
  const newPosition = Math.max(0, Math.min(offsetX / rect.width, 1));
  draggedPosition.value = newPosition * 100; // 更新拖动指针的百分比位置

  // 仅更新 `currentTime`，不实时更新 `player.currentTime`
  currentTime.value = newPosition * player.value.duration;
};

// 停止拖动
const stopDragging = () => {
  isDragging.value = false;
  document.removeEventListener('mousemove', onDragging);
  document.removeEventListener('mouseup', stopDragging);

  // 松开鼠标后更新播放器时间
  if (player.value) {
    player.value.currentTime = currentTime.value;
    store.commit('ViewEdit/setCurrentTime', currentTime.value);
  }

  // 恢复播放状态
  if (store.state.ViewEdit.isPlaying && player.value) {
    player.value.play();
  }
};

// 播放或暂停
const togglePlayPause = () => {
  if (!player.value) return;

  if (player.value.paused) {
    player.value.play().then(() => {
      setTimeout(() => {
        store.commit('ViewEdit/setPlayingState', true); // 确保播放开始后再更新状态
      }, 50);
    }).catch(error => {
      console.error("尝试播放时出错:", error);
    });
  } else {
    player.value.pause();
    setTimeout(() => {
      store.commit('ViewEdit/setPlayingState', false); // 确保暂停后再更新状态
    }, 50);
  }
};

// 启动视频帧绘制到 Canvas
const startDrawingFrames = () => {
  store.dispatch('ViewEdit/startDrawingFrames');
};

// 停止绘制帧
const stopDrawingFrames = () => {
  store.dispatch('ViewEdit/stopDrawingFrames');
}

// 格式化时间为 mm:ss
const formattedTime = computed(() => {
  const currentTime = store.state.ViewEdit.currentTime; // 获取当前播放时间
  const minutes = Math.floor(currentTime / 60);
  const seconds = Math.floor(currentTime % 60).toString().padStart(2, '0');
  return `${minutes}:${seconds}`;
})


// 生成时间刻度
const generateTimeMarks = () => {
  if (player.value && player.value.duration) {
    const duration = Math.floor(player.value.duration);

    const newTimeMarks = Array.from({ length: duration + 1 }, (_, index) => {
      const time = index;
      const position = (time / duration) * 100;
      const minutes = Math.floor(time / 60);
      const seconds = (time % 60).toString().padStart(2, '0');
      return { time, position, label: `${minutes}:${seconds}` };
    });

    store.commit('ViewEdit/setTimeMarks', newTimeMarks);
    console.log("Generated time marks:", newTimeMarks);
  }
};


onMounted(async () => {
  // 设置视频播放器和时间轴容器
  player.value = document.querySelector('.video-player') as HTMLVideoElement;
  timelineContainer.value = document.getElementById('timeline-container') as HTMLElement;

  // 合成视频的 URL 设置到播放器中
  if (player.value && combinedVideoUrl.value) {
    player.value.src = combinedVideoUrl.value;
    console.log("Combined video URL set to player:", combinedVideoUrl.value);
  }

  // 播放器的时间更新事件
  const timeUpdateHandler = () => {
    const currentTime = player.value?.currentTime || 0;
    store.commit('ViewEdit/setCurrentTime', currentTime);

    if (player.value?.ended) {
      store.commit('ViewEdit/setPlayingState', false);
    }
  };

  // 视频元数据加载事件
  const metadataLoadedHandler = generateTimeMarks;

  // 监听播放器时间更新事件
  player.value?.addEventListener('timeupdate', timeUpdateHandler);

  // 视频元数据加载完成后生成时间刻度
  player.value?.addEventListener('loadedmetadata', metadataLoadedHandler);

  // 监听键盘事件，支持空格键切换播放/暂停
  const keydownHandler = (event: KeyboardEvent) => {
    if (event.code === 'Space') {
      event.preventDefault();
      togglePlayPause();
    }
  };

  // 动态监听 `isShowDetails` 的变化
  watch(
    () => store.state.ViewEdit.isShowDetails,
    (newVal) => {
      if (!newVal) {
        // 当 isShowDetails 为 false 时添加监听器
        document.addEventListener('keydown', keydownHandler);
      } else {
        // 当 isShowDetails 为 true 时移除监听器
        document.removeEventListener('keydown', keydownHandler);
      }
    },
    { immediate: true } // 确保初始状态也执行
  );

  // 监听点击事件，用于处理点击外部关闭等操作
  document.addEventListener('click', handleClickOutside);

  // 组件销毁时移除所有监听器
  onUnmounted(() => {
    player.value?.removeEventListener('timeupdate', timeUpdateHandler);
    player.value?.removeEventListener('loadedmetadata', metadataLoadedHandler);
    document.removeEventListener('keydown', keydownHandler);
    document.removeEventListener('click', handleClickOutside);
  });
});

// 添加和减少镜头功能
const addSegment = () => console.log('增加镜头');
const removeSegment = () => console.log('减少镜头');

const downloadAllAssets = async () => {
  const assetUrls = await store.dispatch('ViewEdit/getAllAssetUrls');

  // 下载每个视频 URL
  for (const [index, url] of assetUrls.videoUrls.entries()) {
    await downloadFile(url, `video-${index + 1}.mp4`);
  }

  // 下载音乐、合成音频和合成视频文件
  if (assetUrls.musicUrl) await downloadFile(assetUrls.musicUrl, 'music.mp3');
  if (assetUrls.generatedAudioUrl) await downloadFile(assetUrls.generatedAudioUrl, 'generated-audio.mp3');
  if (assetUrls.combinedVideoUrl) await downloadFile(assetUrls.combinedVideoUrl, 'combined-video.mp4');

  // 获取 tableData 并下载每个 row.image
  const tableData = store.getters['ViewStoryboard/getTableData'] || [];
  for (const [index, row] of tableData.entries()) {
    if (row.image) {
      await downloadFile(row.image, `image-${index + 1}.jpg`);
    }
  }
};

// 使用 fetch 和 Blob 实现文件下载，避免打开链接
const downloadFile = async (url: string, fileName: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    // 打印下载内容的信息
    console.log(`Downloading file: ${fileName}`);
    // console.log(`URL: ${url}`);
    // console.log(`File type: ${blob.type}`);
    // console.log(`File size: ${blob.size} bytes`);

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // 释放 URL 对象
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error(`Failed to download file from ${url}`, error);
  }
};

onActivated(() => console.log('VideoEditingView 被激活'));
onDeactivated(() => console.log('VideoEditingView 被缓存'));


return (_ctx: any,_cache: any) => {
  return (hasPermission.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("video", {
              ref: "videoPlayer",
              class: "video-player",
              controls: "",
              src: combinedVideoUrl.value,
              onPlay: startDrawingFrames,
              onPause: stopDrawingFrames,
              crossorigin: "anonymous"
            }, null, 40, _hoisted_4),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", null, _toDisplayString(formattedTime.value), 1),
              _createElementVNode("button", {
                onClick: togglePlayPause,
                class: "play-pause-button"
              }, _toDisplayString(isPlaying.value ? '暂停' : '播放'), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(timeMarks.value, (mark) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: mark.time,
                    class: "time-mark",
                    style: _normalizeStyle({ left: mark.position + '%' })
                  }, _toDisplayString(mark.label), 5))
                }), 128))
              ]),
              _createElementVNode("div", {
                style: _normalizeStyle({ left: pointerPosition.value + '%' }),
                class: "playback-pointer",
                onMousedown: startDragging
              }, null, 36)
            ]),
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(videoSegments.value, (segment, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass([{ 'selected-segment': _unref(store).state.ViewEdit.selectedVideo === segment.video_id }, "video-segment"]),
                  style: _normalizeStyle({ width: segment.width + '%', backgroundColor: segment.backgroundColor, }),
                  onClick: ($event: any) => (selectSegment(segment.video_id))
                }, [
                  _createElementVNode("img", {
                    src: segment.thumbnail,
                    class: "segment-thumbnail",
                    crossorigin: "anonymous"
                  }, null, 8, _hoisted_11)
                ], 14, _hoisted_10))
              }), 128)),
              _createElementVNode("div", {
                style: _normalizeStyle({ left: pointerPosition.value + '%' }),
                class: "playback-pointer",
                onMousedown: startDragging
              }, null, 36)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "audio-track-bar" }, null, -1)),
              _createElementVNode("div", {
                style: _normalizeStyle({ left: pointerPosition.value + '%' }),
                class: "playback-pointer",
                onMousedown: startDragging
              }, null, 36)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "subtitle-track-bar" }, null, -1)),
              _createElementVNode("div", {
                style: _normalizeStyle({ left: pointerPosition.value + '%' }),
                class: "playback-pointer",
                onMousedown: startDragging
              }, null, 36)
            ])
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("input", {
            type: "range",
            id: "zoom-slider",
            min: "10",
            max: "120",
            value: "60"
          }, null, -1)),
          _createElementVNode("div", { class: "control-buttons" }, [
            _createElementVNode("button", { onClick: addSegment }, "增加镜头"),
            _createElementVNode("button", { onClick: removeSegment }, "减少镜头"),
            _createElementVNode("button", { onClick: downloadAllAssets }, "一键下载所有素材")
          ])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_14, [
          _createVNode(CameraDetails)
        ], 512), [
          [_vShow, showDetails.value]
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[3] || (_cache[3] = [
        _createElementVNode("h1", null, "无权访问该页面", -1)
      ])))
}
}

})